import {
    AppBar,
    Card,
    Dialog,
    Divider,
    IconButton,
    Skeleton,
    Stack,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {useLocales} from "../../locales";
import {Controller, useFormContext} from "react-hook-form";
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment";
import Iconify from "../../components/Iconify";
import * as React from "react";
import {useState} from "react";
import {DIRECTION_NAME} from "../../constants";
import Image from "../../components/image";
import {getSessionUser} from "../../helper/session";
import {fCurrency} from "../../utils/formatNumber";
import {getCarrierNameByTin, getPriceByDirectionType} from "./helper";
import {findFlag} from "./Results";
import useResponsive from "../../hooks/useResponsive";
import {searchTripApi} from "../../api/trip";
import {AUTH} from "../../auth/FirebaseContext";
import EmptyContent from "../../components/empty-content";
import {CalendarIconSvg} from "../../constants/icons";

export default function ReturnTripDialog({open, onClose, onlyClose}) {
    const {translate} = useLocales();
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const {
        setValue,
        control,
        getValues,
    } = useFormContext();

    const isDesktop = useResponsive("up", "lg");

    const ondDateChange = async (value) => {
        await getAndConvertTrips(
            getValues("endStation")?.id,
            getValues("startStation")?.id,
            moment(value).format("yyyy-MM-DD hh:mm:ss"))
    }

    const getAndConvertTrips = async (fromStationId, toStationId, journeyDate) => {
        setLoading(true)
        await searchTripApi({
            departureStationId: fromStationId,
            arrivalStationId: toStationId,
            from: journeyDate,
            operatorUid: AUTH.currentUser.uid
        }).then(async response => {
            const arr = response.data;
            const arrCarriersUniqueList = Array.from(new Set(arr.filter(i => i.issuedFor !== null).map(i => i.issuedFor)))
            const namesOfCarriers = await getCarrierNameByTin(arrCarriersUniqueList)
            const newArr = arr.map((element) => {
                let carrierName = namesOfCarriers.find(i => i.tin === element.issuedFor)
                const hours = Math.floor(element.timeTravelInMinutes / 60);
                const minutes = element.timeTravelInMinutes % 60;
                const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
                const formattedHours = hours < 10 ? `0${hours}` : hours;
                const time = `${formattedHours}:${formattedMinutes}`;
                const haveBorder = (element?.borderName || "").length > 0;
                let fromBorderDistance = element.fromBorderDistance
                let toBorderDistance = element.toBorderDistance
                if (haveBorder) {
                    if (element.depCountryName === "Srbija" && !fromBorderDistance) {
                        fromBorderDistance = 0
                    }
                    if (element.arrCountryName === "Srbija" && !toBorderDistance) {
                        toBorderDistance = 1
                    }
                }
                return {
                    id: element.tripId,//
                    tripId: element.tripId,//
                    operatorId: element.operatorId,//
                    departureTripTime: element.departureTime,//
                    arrTripTime: element.arrivalTime,//
                    issuedFor: carrierName || null,//
                    carrier: carrierName?.name || element.operatorName || null,
                    lineId: element.lineId,//
                    lineName: element.lineName,//
                    tripTime: time,
                    depStationId: fromStationId,
                    arrStationId: toStationId,
                    depStationName: element.departureStationName,//
                    depRegionName: element.departureRegionName || "/",//
                    depCountryName: element.departureCountryName,
                    arrStationName: element.arrivalStationName,//
                    arrCountryName: element.arrivalCountryName,//
                    arrCountryCode: element.arrivalCountryAlpha2,//
                    priceOneWay: element?.price || 0,//
                    tripUid: element?.tripUid,//
                    priceReturnTicket: element?.returnPrice || 0,//
                    priceMonthly: element?.monthlyPrice || 0,//
                    operatorName: element.operatorName,//
                    printText: element?.printText,//
                    arrRegionName: element.arrivalRegionName || "/",//
                    depBorderDistance: toBorderDistance,
                    arrBorderDistance: fromBorderDistance,
                    borderName: element.borderName,
                    timeTravelInMinutes: element.timeTravelInMinutes,
                    freeSeats: element.freeSeats,
                    seatsReserved: element.seatsReserved,
                    numberOfVehicles: element.numberOfVehicles,
                    reservedSeats: element.reservedSeats,
                    totalSeats: element.totalSeats,
                    platformNumber: element.platformNumber,
                    comment: element?.comment,
                    commentInternal: element?.commentInternal,
                    routeComment: element?.routeComment,
                    canceledNote: element?.canceledNote,
                    canceled: element?.canceled,
                    requiresReturnDate: element?.requiresReturnDate,
                    maxDaysOfReturnDate: element?.maxDaysOfReturnDate,
                    alTotalSeats: element?.alTotalSeats,
                    alSeatNumbered: element?.seatsNumbered,
                    externalSale: !!element?.externalSale,
                    externalPartner: element?.externalPartner || null,
                    trafficType: element?.trafficType || null,
                    rateCardId: element?.rateCardId || null,
                    allowedViewers: element?.allowedViewers || null,
                }
            })
            setResults(newArr);
        })
            .catch(e => console.error(e, 'ee'))
            .finally(() => {
                setLoading(false);
            })
    }

    const handleCardPress = (item) =>{
        setValue("returnTrip", item);
        onlyClose();
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}>
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        {translate("chooseReturnTrip")}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close">
                        X
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Card sx={{p: 3, mt: 2, my: 2}}>
                <Stack direction="row" gap={1.5}>
                    <TextField
                        InputProps={{
                            size: "small"
                        }}
                        disabled={true}
                        value={getValues("endStation")?.name || getValues("endStation")?.stationName}
                    />
                    <TextField
                        InputProps={{
                            size: "small"
                        }}
                        disabled={true}
                        value={getValues("startStation")?.name}/>
                    <Controller
                        name="dateTo"
                        control={control}
                        render={({field, fieldState: {}}) => (<DatePicker
                            {...field}
                            minDate={getValues("dateFrom")}
                            onChange={async (newValue) => {
                                field.onChange(newValue)
                                ondDateChange(newValue);
                                if (moment(getValues('dateTo')).isBefore(newValue)) {
                                    setValue('dateTo', newValue)
                                }
                            }}
                            label="Na dan"
                            inputFormat="dd/MM/yyyy"
                            slotProps={{ textField: { size: 'small' } }}
                            slots={{ openPickerIcon: CalendarIconSvg }}
                            renderInput={(params) => (<TextField
                                {...params}
                            />)}/>)}
                    />
                </Stack>
            </Card>
            <Stack direction="column" gap={1.5} sx={{px: 2}}>
                {loading ?
                    Array.from({length: 3}, (_, i) => (
                        <Skeleton
                            key={`return-Trip-skeleton-${i}`}
                            variant="rect"
                            height={150}
                            width="100%"/>
                    ))
                    :
                    results.map(item => {
                        const flagArr = findFlag(item?.arrCountryName)
                        const flagDep = findFlag(item?.depCountryName)
                        return (
                            <Stack
                                onClick={() => handleCardPress(item)}
                                direction="row"
                                sx={{
                                    width: "100%",
                                    background: "transparent",
                                    borderRadius: 2,
                                    mt: 2
                                }}>
                                <Card sx={{
                                    width: "100%",
                                    "&:hover": {
                                        cursor: "pointer",
                                        background: "#f0f1f2",
                                    }
                                }}>
                                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                                        <Stack sx={{alignItems: 'center', justifyContent: 'center'}}>
                                            <Typography
                                                variant="h3"
                                                sx={{
                                                    fontWeight: 700,
                                                    color: "#6DABE4"
                                                }}>
                                                {moment(item.departureTripTime).format("HH:mm")}
                                            </Typography>
                                            <Typography sx={{fontSize: 16, letterSpacing: 1.2}}
                                                        variant="subtitle1">
                                                {item.depStationName}
                                            </Typography>
                                            <Typography sx={{letterSpacing: 1.2}}
                                                        variant="caption">
                                                ({item.depCountryName})
                                            </Typography>
                                        </Stack>
                                        <Image src="/assets/icons/home/bus-outbound.svg"
                                               disabledEffect
                                               alt="pin"
                                               sx={{height: 64}}
                                        />
                                        <Stack direction="column" alignItems="center">
                                            <Typography variant="subtitle1" sx={{
                                                fontSize: 18,
                                                color: "text.disabled"
                                            }}>{item?.depStationName?.toUpperCase()?.slice(0, 3)}</Typography>
                                            <Divider orientation="vertical"
                                                     sx={{width: '100%', borderRadius: 5, borderWidth: 3}}/>

                                            {flagDep !== "" ?
                                                <Image src={flagDep}
                                                       disabledEffect
                                                       alt="pin"
                                                       sx={{height: 30}}
                                                />
                                                :
                                                <Typography variant="subtitle2"
                                                            sx={{color: "text.disabled"}}>{findFlag(item?.depCountryName)}</Typography>
                                            }
                                        </Stack>
                                        <Stack sx={{display: 'flex', flex: 1}}>
                                            {item?.issuedFor?.name?.length > 0 ?
                                                <Stack spacing={1}>
                                                    <Typography variant="h6"
                                                                sx={{
                                                                    letterSpacing: 1.2,
                                                                    textAlign: 'center',
                                                                    color: 'success.main'
                                                                }}>
                                                        {item?.issuedFor?.name?.toUpperCase()}
                                                    </Typography>
                                                </Stack>
                                                :
                                                <Typography variant="h6"
                                                            sx={{
                                                                letterSpacing: 1.2,
                                                                textAlign: 'center',
                                                                color: 'success.main'
                                                            }}>
                                                    {getSessionUser()?.name?.toUpperCase()}
                                                </Typography>
                                            }
                                            <Typography variant="subtitle2"
                                                        sx={{
                                                            fontStyle: 'italic',
                                                            textAlign: 'center',
                                                            color: 'success.info'
                                                        }}>
                                                PREVOZNIK: {item?.operatorName?.toUpperCase()}
                                            </Typography>
                                            <Divider textAlign="center">
                                                <Typography variant="subtitle2"
                                                            sx={{color: "text.disabled"}}>{item?.tripTime}</Typography>
                                            </Divider>

                                            <Typography variant="subtitle2"
                                                        sx={{
                                                            fontStyle: 'italic',
                                                            textAlign: 'center',
                                                            color: 'success.info'
                                                        }}>
                                                LINIJA: {item?.lineName?.toUpperCase()}
                                            </Typography>
                                        </Stack>

                                        <Stack direction="column" alignItems="center">
                                            <Typography variant="subtitle1"
                                                        sx={{color: "text.disabled"}}>
                                                {item.arrStationName?.slice(0, 3)?.toUpperCase()}
                                            </Typography>
                                            <Divider orientation="vertical"
                                                     sx={{width: '100%', borderRadius: 5, borderWidth: 3}}/>
                                            {flagArr !== "" ?
                                                <Image src={flagArr}
                                                       disabledEffect
                                                       alt="pin"
                                                       sx={{height: 30}}
                                                />
                                                :
                                                <Typography variant="subtitle2"
                                                            sx={{color: "text.disabled"}}>{findFlag(item?.arrCountryName)}</Typography>
                                            }
                                        </Stack>
                                        <Image src="/assets/icons/home/bus-inbound.svg"
                                               disabledEffect
                                               alt="pin"
                                               sx={{height: 64}}
                                        />
                                        <Stack sx={{alignItems: 'center', justifyContent: 'center'}}>
                                            <Typography variant="h3"
                                                        sx={{
                                                            fontWeight: 700,
                                                            color: "#6DABE4"
                                                        }}>{moment(item?.arrTripTime).format("HH:mm")}</Typography>
                                            <Typography sx={{fontSize: 16, letterSpacing: 1.2}}
                                                        variant="subtitle1">{item?.arrStationName}</Typography>
                                            <Typography sx={{letterSpacing: 1.2}}
                                                        variant="caption">({item.arrCountryName})</Typography>
                                        </Stack>
                                    </Stack>
                                </Card>
                                <Divider
                                    orientation={isDesktop ? 'vertical' : 'horizontal'}
                                    flexItem
                                    sx={{borderRightWidth: 4, borderStyle: 'dashed'}}
                                />
                                <Card sx={{
                                    border: item.canceled ? "2px dashed red" : null,
                                    p: 2, width: '20%',
                                    "&:hover": {
                                        cursor: "pointer",
                                        background: "#f0f1f2",
                                    }
                                }}>
                                    <Stack>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography variant="subtitle2"
                                                        sx={{fontWeight: 500, color: "text.disabled"}}>
                                                Broj vozila:
                                            </Typography>
                                            <Stack alignItems="center" direction="row" spacing={1}>
                                                <Typography variant="subtitle2"
                                                            sx={{
                                                                fontWeight: 500,
                                                                color: "text.disabled"
                                                            }}>{item?.numberOfVehicles}</Typography>
                                                <Iconify sx={{color: '#6DABE4'}} icon="mingcute:bus-line" width={16}/>
                                            </Stack>
                                        </Stack>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography variant="subtitle2"
                                                        sx={{fontWeight: 500, color: "text.disabled"}}>
                                                Broj mesta:
                                            </Typography>
                                            <Stack alignItems="center" direction="row" spacing={1}>
                                                <Typography variant="subtitle2"
                                                            sx={{
                                                                fontWeight: 500,
                                                                color: "text.disabled"
                                                            }}>{item?.freeSeats}</Typography>
                                                <Iconify sx={{color: '#6DABE4'}} icon="ic:round-event-seat" width={18}/>
                                            </Stack>
                                        </Stack>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography variant="subtitle2"
                                                        sx={{fontWeight: 500, color: "text.disabled"}}>
                                                Broj rezervacija:
                                            </Typography>
                                            <Stack alignItems="center" direction="row" spacing={1}>
                                                <Typography variant="subtitle2"
                                                            sx={{
                                                                fontWeight: 500,
                                                                color: "text.disabled"
                                                            }}>{item?.seatsReserved}</Typography>
                                                <Iconify sx={{color: '#6DABE4'}} icon="ic:round-event-seat" width={18}/>
                                            </Stack>
                                        </Stack>
                                        <Stack alignItems="center" direction="row" justifyContent="space-between">
                                            <Typography variant="subtitle2"
                                                        sx={{fontWeight: 500, color: "text.disabled"}}>
                                                Broj perona:
                                            </Typography>
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant="subtitle2"
                                                            sx={{
                                                                fontWeight: 500,
                                                                color: "text.disabled"
                                                            }}>
                                                    {item?.platformNumber || "/"}
                                                </Typography>
                                                <Iconify sx={{color: '#6DABE4'}} icon="tabler:bus-stop" width={16}/>
                                            </Stack>
                                        </Stack>
                                        <Typography variant="h5"
                                                    sx={{textAlign: 'right', color: 'success.main', mt: 1}}>
                                            {`${fCurrency(getPriceByDirectionType(DIRECTION_NAME[0], item)?.toString() || "0")} din`}
                                        </Typography>
                                    </Stack>
                                </Card>
                            </Stack>
                        )
                    })
                }
                {(results.length === 0 && !loading) &&
                    <EmptyContent
                        title={translate("noTripsOnDate")}
                        sx={{
                            '& span.MuiBox-root': {height: 160},
                        }}
                    />
                }
            </Stack>
        </Dialog>
    );
}
