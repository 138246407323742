import {customAxiosBE} from "../config";
import axios from "axios";
import {parsePaginationParams} from "../../utils/other";

// VODIMO SE POLITIKOM DA NECE BITI OVDE VISE OD 2k RESULTS
export async function getAllLinesByOperatorIdAPI(id) {
    return customAxiosBE.get(`/line/allByOperatorId/${id}`)
}

export async function getLineByIdAPI(id) {
    return customAxiosBE.get(`/line/${id}`)
}

export async function getCommentAPI(lineId) {
    return customAxiosBE.get(`/line/comment/${lineId}`)
}

export async function getLineTrips(lineId) {
    return customAxiosBE.get(`/line/${lineId}/trips`)
}

export async function checkIfStationExistOnLineAPI(payload) {
    const {lineId, stationId} = payload
    return customAxiosBE.get(`/line/firstStationId/${lineId}/${stationId}`)
}
export async function updateLineByIdAPI(data) {
    return customAxiosBE.put(`/line`, data)
}

export async function updateBySubOperatorByIdAPI(data) {
    return customAxiosBE.put(`line/updateBySubOperator`, data)
}

export async function activateLineApi(id) {
    return customAxiosBE.post(`/line/${id}/activate`)
}

export async function deactivateLineApi(id) {
    return customAxiosBE.delete(`/line/${id}`)
}

export async function deleteAllByLineIdAPI(id) {
    return customAxiosBE.delete(`/line/delete/${id}`)
}

export async function insertLineByIdAPI(data) {
    return customAxiosBE.post(`/line`, data)
}

export async function copyLineApi(data) {
    return customAxiosBE.post(`/line/copy`, data)
}

export async function pureCopyLineApi(id) {
    return customAxiosBE.post(`/line/pureCopy/${id}`)
}

export async function getAllLinesBy(stationId) {
    return customAxiosBE.get(`/line/getAllLinesBy/${stationId}`)
}

export const searchLineAPI = async (data, cancelToken) => {
    let response
    const parsedParams = parsePaginationParams(data)

    await customAxiosBE
        .post(`/line/search?${parsedParams}`, {
                name: data.name || null,
                licenceNumber: data.licenceNumber || null,
                issuedFor: data.issuedFor || null,
                status: data.status === undefined ? null : data.status,
                operatorId: data.operatorId || null,
            },
            {
                cancelToken
            })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            console.error('usao u err')
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}

export const searchLineByOperatorAPI = async (data, cancelToken) => {
    let response
    const parsedParams = parsePaginationParams(data)
    await customAxiosBE
        .post(`/line/searchLineTrip?${parsedParams}`, {
                name: data?.name || null,
                licenceNumber: data?.licenceNumber || null,
                operatorId: data?.operatorId || null,
            },
            {
                cancelToken
            })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            console.error('usao u err')
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}

export async function getAllLinesWithTripsAndStationsForLink(stationId) {
    return customAxiosBE.get(`/line/getAllLinesWithTripsAndStationsForLink/${stationId}`)
}


export const searchLineDTOAPI = async (data, cancelToken) => {
    let response
    const parsedParams = parsePaginationParams(data)
    let statusParsed = null
    if(data.status === "AKTIVAN"){
        statusParsed = true
    } else if (data.status === "PASIVAN"){
        statusParsed = false
    }
    await customAxiosBE
        .post(`/line/searchLine?${parsedParams}`, {
                name: data?.name || null,
                operatorName: data?.operatorName || null,
                licenceNumber: data?.licenceName || null,
                operatorId: data?.operatorId || null,
                status: statusParsed,
            },
            {
                cancelToken
            })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            console.error('usao u err')
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}

export async function getAllLinesAPI(params) {
    const parsedParams = parsePaginationParams(params)
    return customAxiosBE.get(`/line?${parsedParams}`)
}

export function getLinesByDepStationAndArrStation(depId, arrId){
    return customAxiosBE.get(`/line/depStation/${depId}/arrStation/${arrId}`)
}
