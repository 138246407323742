import PropTypes from "prop-types";
// @mui
import {Autocomplete, Box, Button, Checkbox, FormControlLabel, InputAdornment, Stack, TextField} from '@mui/material';
import Iconify from "../../components/Iconify";
import {DatePicker} from "@mui/x-date-pickers";
import {CalendarIconSvg} from "../../constants/icons";
import {useEffect} from "react";
import {getStationsBeforeAPI} from "../../api/lineStation";
// components


DepartureFromSpotToolbar.propTypes = {
    isFiltered: PropTypes.bool,
    filterLineName: PropTypes.string,
    filterOperatorName: PropTypes.string,
    filterDate: PropTypes.string,
    enaleAllStations: PropTypes.bool,
    onResetFilter: PropTypes.func,
    onFilterOperatorName: PropTypes.func,
    onFilterLineName: PropTypes.func,
    onFilterDate: PropTypes.func,
};

export default function DepartureFromSpotToolbar({
                                                     arrStationId,
                                                     depStationId,
                                                     enableAllStations,
                                                     setEnableAllStations,
                                                     setEndStation,
                                                     isIndividual,
                                                     homeArrStations,
                                                     operator,
                                                     isFiltered,
                                                     filterLineName,
                                                     filterOperatorName,
                                                     filterDate,
                                                     onFilterLineName,
                                                     onFilterDate,
                                                     onFilterOperatorName,
                                                     onResetFilter,
                                                 }) {

    const arr = homeArrStations?.length > 0
        ? [
            // {
            //     id: null,
            //     locationId: homeArrStations[0].locationId,
            //     name: `${homeArrStations[0].locationName} (Lokacija)`
            // },
            ...homeArrStations.map(item => ({
                id: item.id,
                name: item.stationName,
                locationId: item.locationId,
                locationName: item.locationName,
            })),
        ]
        : [];

    const locationID =  homeArrStations.find(i => i.id === arrStationId)?.locationId
    const returnStations = homeArrStations.filter(f => f.locationId === locationID).map(item => ({
        id: item.id,
        name: item.stationName,
        locationId: item.locationId,
        locationName: item.locationName,
    }))


    // useEffect(() => {
    //     getStationsBeforeAPI(depStationId,locationID).then(response => {
    //         console.log(response)
    //     })
    // }, []);

    const showStationsToChoose = isIndividual !== false ? enableAllStations : true

    return (
        <>
            <Stack
                spacing={2}
                alignItems="center"
                direction={{
                    xs: 'column',
                    sm: 'row',
                }}
                sx={{px: 2.5, py: 3}}
            >
                {showStationsToChoose &&
                    <Autocomplete
                        fullWidth
                        name="endStation"
                        onChange={(e, data) => {
                            if (data) {
                                setEndStation(data);
                            }
                        }}
                        options={enableAllStations ? returnStations : arr}
                        getOptionLabel={(option) => option.name || ""}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                size="small"
                                label={"Izaberite stanicu"}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />

                }

                <TextField
                    size={"small"}
                    fullWidth
                    value={filterLineName}
                    onChange={onFilterLineName}
                    placeholder="Naziv linije..."
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                            </InputAdornment>
                        ),
                    }}
                />

                {
                    !operator && <TextField
                        size={"small"}
                        fullWidth
                        value={filterOperatorName}
                        onChange={onFilterOperatorName}
                        placeholder="Naziv prevoznika..."
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                                </InputAdornment>
                            ),
                        }}
                    />
                }

                <DatePicker
                    disablePast={true}
                    sx={{width: 550}}
                    label="Datum"
                    value={new Date(filterDate) || null}
                    onChange={onFilterDate}
                    slotProps={{textField: {size: 'small'}}}
                    slots={{openPickerIcon: CalendarIconSvg}}
                    renderInput={(params) => (
                        <TextField
                            size="small"
                            {...params}
                        />
                    )}
                />


                {
                    isFiltered && (
                        <Button
                            color="error"
                            sx={{flexShrink: 0}}
                            onClick={onResetFilter}
                            startIcon={<Iconify icon="eva:trash-2-outline"/>}
                        >
                            Očisti
                        </Button>
                    )
                }
            </Stack>
            {isIndividual &&
                <Stack width={1} pl={3}>
                    <FormControlLabel

                        control={
                            <Checkbox
                                checked={enableAllStations}
                                onChange={(event) => setEnableAllStations(event.target.checked)}/>}
                        label="Uključi prikaz svih stanica za ovu lokaciju u pretrazi"/>
                </Stack>
            }
        </>
    )
}
