import {getLastInsertedOfflineInvoice, putInvoiceOffline, storeErrorToOffline} from "../store/offlineDb";
import {collection, getDocs, limit, orderBy, query, startAfter} from "firebase/firestore";
import {AUTH, DB} from "../auth/FirebaseContext";
import moment from "moment/moment";
import {nameOrDefault} from "./naming";
import {TICKET_TYPE_BASIC_NAME} from "../constants/other";
import {fCurrency} from "../utils/formatNumber";

const INVOICE_TYPE = ["Normal", "Proforma", "Copy", "Training", "Advance"];
export const TRANSACTION_TYPE = ["Sale", "Refund"];
const PAYMENT_TYPE = ["Other", "Cash", "Card", "Check", "WireTransfer", "Voucher", "MobileMoney"];

export function parseInvoice(invoice, formatSdcDate, timestamp) {
    let payments = [];
    if (invoice.paymentMethod !== undefined) {
        invoice.paymentMethod.forEach(value => {
            payments.push({
                paymentType: PAYMENT_TYPE[parseInt(value.paymentType)],
                amount: value.amount
            })
        })
    }
    let transactionType = TRANSACTION_TYPE[parseInt(invoice.transactionType)];
    let invoiceType = INVOICE_TYPE[parseInt(invoice.invoiceType)];
    let sdcDateTime = invoice.sdcDateTime;
    if (formatSdcDate) {
        let date = invoice.sdcDateTime.substring(0, 19);
        sdcDateTime = date.replace("T", " ");
    }
    return {
        ...invoice,
        timestamp: timestamp,
        paymentMethod: payments,
        transactionType: transactionType,
        invoiceType: invoiceType,
        sdcDateTime: sdcDateTime
    };
}

export async function getInvoicesByUserForPeriod(arr, resolve, reject, period) {
    const startDate = period.startDate;
    const invoice = period.foundInvoice;

    if (!startDate || !AUTH.currentUser) {
        return reject();
    }

    let lastDocument = null;
    let hasMoreData = true;

    const batchSize = 50;

    while (hasMoreData) {
        try {
            let q;

            if (lastDocument) {
                q = query(
                    collection(DB, `invoices/users/${AUTH.currentUser.uid}`),
                    orderBy("sdcDateTime"),
                    startAfter(lastDocument),
                    limit(batchSize)
                );
            } else {
                q = query(
                    collection(DB, `invoices/users/${AUTH.currentUser.uid}`),
                    orderBy("sdcDateTime"),
                    startAfter(moment(startDate).format('YYYY-MM-DD')),
                    limit(batchSize)
                );
            }

            const querySnapshot = await getDocs(q);
            const snapshotSize = querySnapshot.docs.length;

            if (snapshotSize === 0) {
                resolve(arr);
                break;
            }

            for (const doc of querySnapshot.docs) {
                const parsedInvoice = parseInvoice(doc.data(), false, doc.id);
                if (parsedInvoice.invoiceNumber !== invoice.invoiceNumber) {
                    arr.push(parsedInvoice);
                    await putInvoiceOffline({
                        ...parsedInvoice,
                        isInsertedOffline: false
                    });
                } else {
                    hasMoreData = false;
                    resolve(arr);
                    break;
                }
                lastDocument = doc;
            }
        } catch (e) {
            console.error("getInvoicesByUserForPeriod", e);
            storeErrorToOffline("getInvoicesByUserForPeriod", "firestore.js", [], e?.toString());
            reject(e);
            break;
        }
    }
}


export async function getInvoicesByUser(lastData, arr, resolve, reject, fDayOfPrevMonth) {
    if (!AUTH.currentUser) {
        return reject();
    }

    if (lastData === null) {
        lastData = await getLastInsertedOfflineInvoice();
    }

    try {
        let q;
        if (!lastData) {
            q = await query(
                collection(DB, `invoices/users/${AUTH.currentUser.uid}`),
                orderBy("sdcDateTime"),
                startAfter(fDayOfPrevMonth),
                limit(50)
            );
        } else {
            q = await query(
                collection(DB, `invoices/users/${AUTH.currentUser.uid}`),
                orderBy("sdcDateTime"),
                startAfter(lastData.sdcDateTime),
                limit(50)
            );
        }

        const querySnapshot = await getDocs(q);
        const snapshotSize = querySnapshot.docs.length;

        if (snapshotSize === 0) {
            resolve(arr);
        }

        let i = 0;

        await querySnapshot.forEach((doc) => {
            const parsedInvoice = parseInvoice(doc.data(), false, doc.id);
            putInvoiceOffline({
                ...parsedInvoice,
                isInsertedOffline: false
            });
            arr.push(parsedInvoice);

            if (i === snapshotSize - 1) {
                getInvoicesByUser(parsedInvoice, arr, resolve, reject);
            }

            i = i + 1;
        });
    } catch (e) {
        console.error("getInvoicesByUser", e);
        storeErrorToOffline("getInvoicesByUserAndLocation", "firestore.js", [], e?.toString());
        reject(e);
    }
}

export const printHTML = async (html, printA4 = false) => {
    let w = window.open(`?a4=${printA4}`, "", "");
    await new Promise(resolve => {
        w.addEventListener("load", resolve);
        w.addEventListener("readystatechange", resolve);
        w.addEventListener("afterprint", (event) => {
            console.debug("After print", event);
        });
    });
    w.document.write(html);
    setTimeout(_ => {
        w.window.print();
        // w.close();
    }, 200);
};


export const getDataForPrintingTicket = (ticket) => {
    let ticketId = ticket.id;
    if (!ticketId) {
        ticketId = ticket.externalTicketId
    }

    let operator = ticket?.operator?.name
    let departureStationName = ticket?.departureStation?.name
    let depLocationName = ticket?.departureStation?.location?.name
    let arrLocationName = ticket?.arrivalStation?.location?.name
    let arrivalStationName = ticket.arrivalStation.name
    let ticketType = nameOrDefault((ticket?.ticketType?.printText || ticket?.ticketType?.name), TICKET_TYPE_BASIC_NAME, true);
    let journeyDate = ticket?.journeyDate ? moment(ticket?.journeyDate).format("DD.MM.YYYY") : "/"
    let departureTime = ticket?.timeFromStation ? moment(ticket.timeFromStation).format("HH:mm") : "/"
    let price = fCurrency(`${ticket.price}`)
    let cashier = ticket.cashier
    let salesTime = moment(ticket.salesTime).format("DD.MM.YYYY HH:mm:ss")
    let platformNumber = ticket?.platformNumber || "/";
    let seat = ticket?.seatReserved;
    if (!ticket.seatIsNumbered || ticket.isReturnTicket) {
        seat = 'NN'
    }
    let passengerDetails = getPassengerDetails(ticket?.passenger);
    let passengerDocumentNumber = ticket?.passenger?.passengerDocumentNumber || "";
    let outboundTicketId = ticket?.outboundTicketId
    let issuedBy = ticket?.issued?.name

    return {
        ticketId,
        operator,
        departureStationName,
        arrivalStationName,
        ticketType,
        journeyDate,
        departureTime,
        price,
        cashier,
        salesTime,
        platformNumber,
        seat,
        passengerDetails,
        passengerDocumentNumber,
        outboundTicketId,
        issuedBy,
        depLocationName,
        arrLocationName
    }
}

const getPassengerDetails = (passenger) => {
    return `${(passenger?.passengerName || "").length > 0 ? passenger?.passengerName : ""} ${(passenger?.passengerLastName || "").length > 0 ? passenger?.passengerLastName : ""}`
}
