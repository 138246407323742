// routes
import {PATH_DASHBOARD} from './routes/path';
import {CACHE_SIZE_UNLIMITED} from "firebase/firestore";

export const BUS_SOFT_API = "https://bus-soft-api-t3ro5cu2ea-lm.a.run.app/"
export const BUS_SOFT_API_LOCAL = "http://localhost:8080/"
export const BUS_SOFT_API_TEST = "https://bus-soft-api-dev-t3ro5cu2ea-lm.a.run.app/"
export const BUS_SOFT_FUNCTIONS_API = "https://europe-central2-itc-bussoft.cloudfunctions.net"

export const ASANA_ACCESS_TOKEN = process.env.REACT_APP_ASANA_ACCESS_TOKEN;
export const ASANA_PROJECT_ID = process.env.REACT_APP_ASANA_PROJECT_ID;
export const ASANA_WORKSPACE_ID = process.env.REACT_APP_ASANA_WORKSPACE_ID;
export const ASANA_USER_ID = process.env.REACT_APP_ASANA_USER_ID;
// ----------------------------------------------------------------------

export const FIREBASE_API = {
    apiKey: "AIzaSyBkuXvaa9msgpBEwTPHRQHcfdzjRN2KqGk",
    authDomain: "itc-bussoft.firebaseapp.com",
    databaseURL: "https://itc-bussoft-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "itc-bussoft",
    storageBucket: "itc-bussoft.appspot.com",
    messagingSenderId: "944718659764",
    appId: "1:944718659764:web:e5da5e36a29f69ee4717e1",
    measurementId: "G-DG9KP8VEDC",
    cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    persistence: true
};


// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
    H_MOBILE: 64,
    H_MAIN_DESKTOP: 88,
    H_DASHBOARD_DESKTOP: 92,
    H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
    W_BASE: 260,
    W_INV_BASE: 460,
    W_DASHBOARD: 280,
    W_DASHBOARD_MINI: 98,
    //
    H_DASHBOARD_ITEM: 48,
    H_DASHBOARD_ITEM_SUB: 36,
    //
    H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
    NAV_ITEM: 24,
    NAV_ITEM_HORIZONTAL: 22,
    NAV_ITEM_MINI: 22,
};
