import {customAxiosBE} from "../config";
import axios from "axios";
import {parsePaginationParams} from "../../utils/other";

// OVO SU CARRIERS
export async function getAllOperatorsAPI(payload) {
    const parsedParams = parsePaginationParams(payload)
    return customAxiosBE.get(`/operator?${parsedParams}`)
}

export async function getAllSubOperatorsAPI() {
    return customAxiosBE.get(`/operator/allSubOperators`)
}


export async function  getOperatorDocumentation(operatorId){
    return customAxiosBE.get(`/operator/${operatorId}/documentation`)
}

export async function  getCooperatorByTripId(lineId){
    return customAxiosBE.get(`operator/cooperators/${lineId}`)
}

export async function updateOperatorDocumentation(data){
    return customAxiosBE.post(`/operator/documentation`, data)
}

export async function insertOperatorAPI(payload) {
    return customAxiosBE.post(`/operator`, payload)
}

export async function updateOperatorAPI(payload) {
    return customAxiosBE.put(`/operator`, payload)
}

export async function changeOperatorStatusAPI(operatorID) {
    return customAxiosBE.put(`/operator/${operatorID}/status`, operatorID)
}

export async function deleteCarrierAPI(id) {
    return customAxiosBE.delete(`/operator/${id}`)
}

export async function findOperatorsBySearchDataAPI(payload) {
    const {name} = payload?.data
    if (!name) {
        return undefined
    }
    return customAxiosBE.post(`/operator/search?page=${payload.page}&size=${payload.rowsPerPage}`, {
        name: name
    })
}

export async function findSubOperatorsBySearchDataAPI(data, cancelToken) {
    let response
    const {operatorId, name} = data
    if (!operatorId) {
        return undefined
    }
    const parsedParams = parsePaginationParams(data)
    await customAxiosBE.post(`/operator/searchSubOperators?${parsedParams}`, {
        id: operatorId,
        name: name
    },{
        cancelToken
    })
        .then((r) => {
            response = r
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}

export async function getOperatorByIdAPI(id) {
    if (!id) {
        return undefined
    }
    return customAxiosBE.get(`/operator/${id}`)
}

export const searchCarrierAPI = async (data, cancelToken) => {
    let response
    const {name, tin, country, id} = data
    const parsedParams = parsePaginationParams(data)
    await customAxiosBE
        .post(`/operator/search?${parsedParams}`, {
                name: name,
                tin: tin,
                country: country,
                id: id,
            },
            {
                cancelToken
            })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            console.error('usao u err')
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}

export async function findOperatorsByTin(tin) {
    return customAxiosBE.get(`/operator/tin/${tin}`)
}
