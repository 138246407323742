import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {Box, Link, ListItemText, Tooltip} from '@mui/material';
// auth
import RoleBasedGuard from '../../../auth/RoleBasedGuard';

//
import {StyledDotIcon, StyledIcon, StyledItem} from './styles';
import Iconify from "../../Iconify";

// ----------------------------------------------------------------------

NavItem.propTypes = {
    open: PropTypes.bool,
    active: PropTypes.bool,
    item: PropTypes.object,
    depth: PropTypes.number,
    isExternalLink: PropTypes.bool,
};

export default function NavItem({item, depth, open, active, isExternalLink, ...other}) {

    const {title, path, icon, info, children, disabled, caption, roles} = item;

    const subItem = depth !== 1;

    const renderContent = (
        <StyledItem depth={depth} active={active} disabled={disabled} caption={!!caption} {...other}>
            {icon && <StyledIcon>{icon}</StyledIcon>}

            {subItem && (
                <StyledIcon>
                    <StyledDotIcon active={active && subItem}/>
                </StyledIcon>
            )}
            <Tooltip
                title={title}
                placement="top-start"
                PopperProps={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [150, -40],
                            },
                        },
                    ],
                }}
                slotProps={{
                    tooltip: {
                        sx: {
                            bgcolor: 'grey.900',
                            color: 'common.white',
                            fontSize: 12,
                            px: 1.5,
                            py: 1,
                            borderRadius: 1.5,
                            boxShadow: 3,
                        },
                    },
                }}
            >
                <ListItemText
                    primary={title}
                    primaryTypographyProps={{
                        noWrap: true,
                        component: 'span',
                        variant: active ? 'subtitle2' : 'body2',
                        sx: {
                            color: active ? 'primary.main' : 'text.primary',
                            fontWeight: active ? 600 : 400,
                        },
                    }}
                    secondaryTypographyProps={{
                        noWrap: true,
                        variant: 'caption',
                        sx: {
                            color: 'text.secondary',
                        },
                    }}
                />
            </Tooltip>

            {info && (
                <Box component="span" sx={{lineHeight: 0}}>
                    {info}
                </Box>
            )}

            {!!children && (
                <Iconify
                    width={16}
                    icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
                    sx={{ml: 1, flexShrink: 0}}
                />
            )}
        </StyledItem>
    );

    const renderItem = () => {
        // ExternalLink
        if (isExternalLink)
            return (
                <Link href={path} target="_blank" rel="noopener" underline="none">
                    {renderContent}
                </Link>
            );

        // Has child
        if (children) {
            return renderContent;
        }

        // Default
        return (
            <Link component={RouterLink} to={path} underline="none">
                {renderContent}
            </Link>
        );
    };

    return <RoleBasedGuard roles={roles}> {renderItem()} </RoleBasedGuard>;
}
