import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    deleteStationAPI,
    getAllArrivalStationsByDepStationId,
    getAllDefaultDepStationsApi,
    getAllStationsApi,
    insertStationAPI,
    searchStationAPI, stationUsageInfoAPI,
    updateStationAPI
} from "../../api/station";

export const fetchHomeStations = createAsyncThunk('fetchHomeStations', async (payload) => {
    return await getAllDefaultDepStationsApi(payload)
})
export const fetchHomeArrStations = createAsyncThunk('fetchHomeArrStations', async (payload) => {
    return await getAllArrivalStationsByDepStationId(payload)
})

export const searchStation = createAsyncThunk('searchStation', async (payload) => {
    return await searchStationAPI(payload)
})

export const fetchStations =
    createAsyncThunk('fetchStations', async (data) => {
        return getAllStationsApi(data);
    })

export const insertStation = createAsyncThunk('insertStation', async (payload, {rejectWithValue}) => {
    try {
        const response = await insertStationAPI(payload);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})

export const updateStation = createAsyncThunk('updateStation', async (payload, {rejectWithValue}) => {
    try {
        const response = await updateStationAPI(payload);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})

export const deleteStation = createAsyncThunk('deleteStation', async (id, {rejectWithValue}) => {
    try {
        const response = await deleteStationAPI(id);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})

export const stationUsageInfo = createAsyncThunk('stationUsageInfo', async (id, {rejectWithValue}) => {
    try {
        const response = await stationUsageInfoAPI(id);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})

const initialState = {
    query: '',
    homeStations: undefined,
    homeArrStations: [],
    stationDepartures: [],
    idOfStartStation: null,
    stations: [],
    loadingStations: false,
    loadingArrStations: false,
    searchParam: ""
}

const stationSlice = createSlice({
    name: 'stations',
    initialState,
    reducers: {
        setQuery: (state, action) => {
            state.query = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchHomeStations.pending, (state) => {
                state.loadingArrStations = true;
            })
            .addCase(fetchHomeStations.fulfilled, (state, {payload}) => {
                state.homeStations = payload.data;
                state.loadingArrStations = false;
            })
            .addCase(fetchHomeStations.rejected, (state) => {
                state.loadingArrStations = false;
            })
            .addCase(fetchHomeArrStations.pending, (state) => {
                state.loadingStations = true;
            })
            .addCase(fetchHomeArrStations.fulfilled, (state, {payload}) => {
                state.loadingStations = false;
                state.homeArrStations = payload.data;
            })
            .addCase(fetchHomeArrStations.rejected, (state) => {
                state.loadingStations = false;
            })
            .addCase(searchStation.pending, (state) => {
                state.loadingStations = true;
            })
            .addCase(searchStation.fulfilled, (state, {payload}) => {
                state.loadingStations = false;
                state.stations = payload.data.content;
            })
            .addCase(searchStation.rejected, (state) => {
                state.loadingStations = false;
            })
            .addCase(fetchStations.pending, (state) => {
                state.loadingStations = true;
            })
            .addCase(fetchStations.fulfilled, (state, {payload}) => {
                state.stations = payload.data;
                state.loadingStations = false;
            })
            .addCase(fetchStations.rejected, (state) => {
                state.loadingStations = false;
            })
            .addCase(deleteStation.fulfilled, (state, action) => {
                let content = state.stations;
                if (Array.isArray(content)) {
                    const index = content.findIndex((st) => st.id === action.meta.arg);
                    if (index !== -1) {
                        content.splice(index, 1);
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(updateStation.fulfilled, (state, {payload}) => {
                const content = state.stations;
                if (Array.isArray(content)) {
                    const index = content.findIndex((station) => station.id === payload.id);
                    if (index !== -1) {
                        content[index] = {...payload, stationName: payload?.name};
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(insertStation.fulfilled, (state, {payload}) => {
                const content = state.stations;
                if (Array.isArray(content)) {
                    content.push(payload);
                } else {
                    console.error('Content is not an array:', content);
                }
            })
    }
});

export const stationsReducer = stationSlice.reducer;
