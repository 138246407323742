import PropTypes from "prop-types";
import {Button, InputAdornment, MenuItem, Stack, TextField} from "@mui/material";
import Iconify from "../../components/Iconify";
import {useLocales} from "../../locales";
import {INPUT_WIDTH} from "../../constants";

CarriersToolbar.propTypes = {
    isFiltered: PropTypes.bool,
    filterName: PropTypes.string,
    filterStatus: PropTypes.string,
    onStatusChange: PropTypes.func,
    onFilterName: PropTypes.func,
    onResetFilter: PropTypes.func,
    showStatus: PropTypes.bool,
};

export default function CarriersToolbar(
    {
        filterName,
        filterStatus,
        onStatusChange,
        onFilterName,
        onResetFilter,
        isFiltered,
        showStatus
    }
) {

    const {translate} = useLocales();

    return (
        <Stack
            spacing={2}
            alignItems="center"
            direction={{
                xs: 'column',
                sm: 'row',
            }}
            sx={{px: 2.5, py: 3}}>

            <TextField
                size={"small"}
                fullWidth
                autoFocus
                value={filterName}
                onChange={onFilterName}
                placeholder={translate("name")}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    ),
                }}
            />

            {showStatus && <TextField
                fullWidth
                select
                size="small"
                label="Status"
                value={filterStatus}
                onChange={onStatusChange}
                SelectProps={{
                    MenuProps: {
                        PaperProps: {
                            sx: {maxHeight: 220},
                        },
                    },
                }}
                sx={{
                    maxWidth: {md: INPUT_WIDTH},
                    textTransform: 'capitalize',
                }}
            >
                {["SVI", "AKTIVAN", "PASIVAN"].map((option) => (
                    <MenuItem
                        key={option}
                        value={option}
                        sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: 'body2',
                            textTransform: 'capitalize',
                            '&:first-of-type': {mt: 0},
                            '&:last-of-type': {mb: 0},
                        }}
                    >
                        {option}
                    </MenuItem>
                ))}
            </TextField>}

            {isFiltered && (
                <Button
                    color="error"
                    sx={{flexShrink: 0}}
                    onClick={onResetFilter}
                    startIcon={<Iconify icon="eva:trash-2-outline"/>}
                >
                    {translate("clear")}
                </Button>
            )}
        </Stack>
    )
}
