import {isTest} from "../session";
import {ESIR_NUMBER, INVOICE_TYPE, PAYMENT_TYPE, TRANSACTION_TYPE} from "../../constants";
import {insertIntoFirestore, insertNewInvoice} from "./index";
import ReactDOMServer from "react-dom/server";
import InvoiceSlip from "../../components/Slip/InvoiceSlip";
import {printPdfLpfr} from "../../api/lpfr";
import QRCode from "qrcode";
import VerificationSlip from "../../components/Slip/VerificationSlip";
import PeronSlip from "../../components/Slip/PeronSlip";
import CouponSlip from "../../components/Slip/CouponSlip";
import TicketSlip from "../../components/Slip/TicketSlip";
import {printHTML} from "../index";
import {getCashierName} from "../../utils/other";

const BUYER_TIN_PREFIX = [10, 11, 12, 20, 21, 22, 23, 30, 32, 33, 34, 35, 40];
const ALL_PAYMENTS_TYPES = [PAYMENT_TYPE.card, PAYMENT_TYPE.cash, PAYMENT_TYPE.check,
    PAYMENT_TYPE.other, PAYMENT_TYPE.voucher, PAYMENT_TYPE.mobileMoney, PAYMENT_TYPE.wireTransfer];
const ALL_INVOICE_TYPES = [INVOICE_TYPE.copy, INVOICE_TYPE.advance, INVOICE_TYPE.normal,
    INVOICE_TYPE.proforma, INVOICE_TYPE.training];

export function convertItemName(name) {
    const chars = {"č": "c", "Č": "C", "ć": "c", "Ć": "c", "đ": "dj", "Đ": "Đ", "š": "s", "Š": "S", "ž": "z", "Ž": "Z"};
    return name?.replace(/[čČćĆđĐšŠžŽ]/g, m => chars[m]);
}

export class Invoice {
    // Main for invoice
    invoice = {
        invoiceNumber: ESIR_NUMBER,
        transactionType: null,
        invoiceType: null,
        buyerId: null,
        dateAndTimeOfIssue: null,
        buyersCosCenter: null,
        referentDocumentNumber: null,
        referentDocumentDT: null,
        payment: null,
        items: null,
        cashier: getCashierName(),
        options: {
            OmitQRCodeGen: "1",
            OmitTextualRepresentation: "0"
        }
    }

    internalData = {
        client: "webBus",
        issuedFor: null,
        cardNumber: null,
        lineNumber: null,
        passenger: null,
        lineType: null,
        discountAmount: null,
    }
    totalCounter;
    // Other
    printLogoInvoice = false;
    printLogoTicket = false;
    ticketValidityPeriod = undefined;
    customer = undefined;
    exchange = undefined;
    logo = undefined;
    ticketLogo = undefined;
    discount = undefined;
    customAdvertisementText = undefined;
    silentPrint = false;
    printerSize = null;
    roundedPrice = false;
    responseInvoice = null;

    // ---------------- ZA KREIRANJE RACUNA - NE DIRATI! -------------------------
    setTransactionType = (transaction) => {
        if (transaction !== TRANSACTION_TYPE.refund && transaction !== TRANSACTION_TYPE.sale) {
            throw new Error("Neispravan tip transakcije: ", transaction);
        }
        this.invoice = {
            ...this.invoice,
            transactionType: transaction
        };
    };

    setInvoiceType = (invoiceType) => {
        if (!ALL_INVOICE_TYPES.includes(invoiceType)) {
            throw new Error("Neispravan tip racuna:", invoiceType);
        }
        this.invoice = {
            ...this.invoice,
            invoiceType: invoiceType
        };
    };

    setBuyerIdFromCustomer = (customer) => {
        if (!customer)
            return;
        let {tin, taxCorePrefix, jbkjc, jmbg} = customer;
        if (!tin) {
            throw new Error("Nije definisan pib kupca!");
        }
        if (!taxCorePrefix) {
            throw new Error("Nije definisan tip kupca!");
        }
        taxCorePrefix = parseInt(taxCorePrefix);
        if (!BUYER_TIN_PREFIX.includes(taxCorePrefix)) {
            throw new Error("Nije ispravan tip kupca!", taxCorePrefix);
        }
        if ([10, 40].includes(taxCorePrefix)) {
            this.invoice = {
                ...this.invoice,
                buyerId: `${taxCorePrefix}:${tin}`
            };
        } else {
            if (taxCorePrefix === 12) {
                if (!jbkjc) {
                    throw new Error("Korisniku nije unet jbkjc!");
                }
                this.invoice = {
                    ...this.invoice,
                    buyerId: `${taxCorePrefix}:${tin}:${jbkjc}`
                };
            } else {
                this.invoice = {
                    ...this.invoice,
                    buyerId: `${taxCorePrefix}:${jmbg}`
                };
            }
        }
    };

    setBuyerId = (buyerId) => {
        if (!buyerId) {
            return;
        }
        if (!buyerId.includes(":")) {
            throw new Error("buyerId is not valid!");
        }
        this.invoice = {
            ...this.invoice,
            buyerId: buyerId
        };
    };

    setRoundedPrice = (isRounded) => {
        this.roundedPrice = isRounded;
    };
    setPayments = (payments, transaction, isRounded) => {
        payments = payments.filter(item => item.amount > 0)
        if (!payments || payments.length === 0) {
            throw new Error("Nacini placanja su prazni!");
        }
        if (payments.length === 1) {
            if (payments[0].amount <= 0 || isNaN(payments[0].amount)) {
                throw new Error(`Nacini placanja ${payments[0].paymentType} je 0 ili nije broj!`);
            }
        } else {
            for (const payment of payments) {
                if (!ALL_PAYMENTS_TYPES.includes(payment.paymentType)) {
                    throw new Error(`Nacini placanja ${payments.paymentType} ne postoji!`);
                }
            }
        }

        const roundPayment = payments.map((payment) => ({
            ...payment,
            amount: transaction !== TRANSACTION_TYPE.refund ? isRounded ? Math.round(payment.amount / 10) * 10 : payment.amount : payment.amount,
        }));
        this.invoice = {
            ...this.invoice,
            payment: roundPayment
        };
    };

    setItems = (items) => {
        if (!items) {
            throw new Error("Morate proslediti proizvode!");
        }
        const requiredFields = ["name", "unitPrice", "quantity", "labels", "totalAmount"];
        let newItems = [];
        for (const item of items) {
            for (const required of requiredFields) {
                if (item[required] === null || item[required] === undefined) {
                    throw new Error(`Morate proslediti ${required}!`);
                }
            }
            let sum = Number(Number(item.quantity).toFixed(3)) * Number(Number(item.unitPrice).toFixed(2));
            sum = Number(Number(sum).toFixed(2));
            if (sum === undefined || isNaN(sum)) {
                throw new Error(`Ukupan iznos za proizvod: ${item.name} nije ispravan!`);
            }

            if (Number(Number(item.totalAmount).toFixed(2)) !== sum) {
                throw new Error(`Ukupan iznos za proizvod: ${item.name} nije tacan!`);
            }

            newItems.push({
                ...item,
                name: convertItemName(item.name),
                quantity: Number(Number(item.quantity).toFixed(3)),
                unitPrice: Number(Number(item.unitPrice).toFixed(2)),
                totalAmount: Number(Number(item.totalAmount).toFixed(2))
            });
        }
        this.invoice = {
            ...this.invoice,
            items: newItems
        };
    };

    setReferentDocumentNumber = (referentDocumentNumber) => {
        this.invoice = {
            ...this.invoice,
            referentDocumentNumber: referentDocumentNumber
        };
    };
    setReferentDocumentDT = (referentDocumentDT) => {
        this.invoice = {
            ...this.invoice,
            referentDocumentDT: referentDocumentDT
        };
    };

    // ----------------- KRAJ KREIRANJA RACUNA -----------------------------------

    setSilentPrint = (silentPrint) => {
        this.silentPrint = silentPrint
    }
    setPrinterSize = (size) => {
        this.printerSize = size
    }
    setExchange = (exchange) => {
        this.exchange = exchange;
    };

    setDiscount = (discount) => {
        this.discount = discount;
    };
    setLogo = (logo) => {
        this.logo = logo;
    };
    setTicketLogo = (logo) => {
        this.ticketLogo = logo;
    };

    setPrintLogoInvoice = (printLogoInvoice) => {
        this.printLogoInvoice = printLogoInvoice;
    };

    setPrintLogoTicket = (printLogoTicket) => {
        this.printLogoTicket = printLogoTicket;
    };

    setCustomAdvertisementText = (item) => {
        this.customAdvertisementText = item;
    };

    setIsTrainingModActive = (isTrainingModActive) => {
        this.isTrainingModActive = isTrainingModActive;
    };

    setInternalData = (data) => {
        const internal = {};
        for (const key in data) {
            if (data[key] !== null && data[key] !== undefined) {
                internal[key] = data[key]
            }
        }
        this.internalData = internal
    }

    setTicketExpirationDate = (ticketValidityPeriod) => {
        this.ticketValidityPeriod = ticketValidityPeriod;
    };

    async sendAndPrintInvoice() {
        validateBasicInvoice(this.invoice);
        if (this.isTrainingModActive) {
            this.invoice = {
                ...this.invoice,
                invoiceType: INVOICE_TYPE.training
            };
        }
        let internalData = {
            ...this.internalData
        };

        await insertNewInvoice({
            ...this.invoice,
            internalData
        }).then(async response => {
            this.totalCounter = response.totalCounter
            this.responseInvoice = response;
            await this.printInvoice(this.invoice, response);
        }).catch(async error => {
            const parsed = await handleSendError(error, this.invoice);
            throw new Error(parsed);
        });
    }

    printVZP = async (firebaseInvoice, tickets, invoice, tin) => {
        const ticketsSize = tickets.length
        this.setBuyerId(tin);
        this.setReferentDocumentNumber(firebaseInvoice.invoiceNumber);
        this.setReferentDocumentDT(firebaseInvoice.sdcDateTime);
        this.setTransactionType(TRANSACTION_TYPE.refund);
        this.setInvoiceType(INVOICE_TYPE.normal);
        this.setItems(invoice.items);
        this.setPayments(invoice.payment)
        await this.printInvoice(this.invoice, invoice);
        await this.storeInvoiceOffline(this.invoice, invoice)

        this.setInvoiceType(INVOICE_TYPE.copy);
        this.setReferentDocumentNumber(invoice.invoiceNumber);
        this.setReferentDocumentDT(invoice.sdcDateTime);
        await this.sendAndPrintInvoice();


        // Penali za VZP 10%
        this.setTransactionType(TRANSACTION_TYPE.sale);
        this.setInvoiceType(INVOICE_TYPE.normal);
        this.setReferentDocumentNumber(null);
        this.setReferentDocumentDT(null);
        const forPay = tickets.reduce((acc, item) => acc + item.price, 0) * 0.1;
        this.setPayments([{
            paymentType: PAYMENT_TYPE.cash,
            amount: Number(forPay.toFixed(2))
        }])

        const totalAmountWithAllItems = invoice.items.reduce((acc, item) => {
            return acc + item.totalAmount;
        }, 0);

        const totalAmount = invoice.items.reduce((acc, item) => {
            return item?.passengerUid?.length !== 0 ? acc + item.totalAmount : acc;
        }, 0);

        //this.setCustomAdvertisementText(`Povraćaj ${(totalAmount - (invoice.items[0].unitPrice * 0.1).toFixed(2))}`)
        this.setItems([{
            name: "Naknada prevoznik " + invoice.items[0].name,
            labels: isTest() ? ['B'] : ['Г'], // TODO KOJA STOPA TREBA OVDE
            quantity: 1,
            unitPrice: Number(Number(forPay / 2).toFixed(2)),
            totalAmount: Number(Number(forPay / 2).toFixed(2)),
        }, {
            name: "Naknada stanica " + invoice.items[0].name,
            labels: isTest() ? ['B'] : ["Ђ"], // TODO KOJA STOPA TREBA OVDE
            quantity: 1,
            unitPrice: Number(Number(forPay / 2).toFixed(2)),
            totalAmount: Number(Number(forPay / 2).toFixed(2)),
        }]);
        this.setExchange(totalAmountWithAllItems - forPay)
        await this.sendAndPrintInvoice();
    }

    storeInvoiceOffline = async (invoice, response) => {
        await insertIntoFirestore(invoice, response);
    }

    printInvoice = async (invoice, response) => {
        if (!this.printLogoInvoice) {
            this.setLogo(undefined);
        }
        try {
            const html = ReactDOMServer.renderToString(InvoiceSlip(invoice, response, this.logo, undefined, this.exchange,
                {
                    text: this.customAdvertisementText
                }, this.ticketValidityPeriod, this.silentPrint, this.printerSize));
            const newHtml = html.replaceAll("replaceToqut", "''");
            if (this.silentPrint) {
                await handleSilentPrint(newHtml, true);
            } else {
                await printHTML(newHtml, false);
            }
        } catch (e) {
            console.error("Invoice printInvoice()", e);
            throw new Error("7532");
        }
    };

    //ticketPrintText - ovo je ono sto je uneo kad je pravio dok je advertisementTextTicket sa fb i ima ga uvek
    printTickets = async (tickets, services, allValues, startStation, endStation, advertisementTextTicket,
                          ticketPrintText, maxDaysOfReturn, alSeatNumbered, confirmation, isReturnTicket) => {
        let parsedTickets = [...tickets]
        if (!this.printLogoTicket) {
            this.setTicketLogo(undefined);
        }
        for (let ticket of parsedTickets) {
            ticket.parsedQR = await QRCode.toDataURL(ticket.forQR, {
                errorCorrectionLevel: 'H',
            });
        }
        const haveReturn = parsedTickets.some(ticket => ticket.isReturnTicket === true);
        if (this.silentPrint) {
            if (parsedTickets.length > 1) {
                if (haveReturn) {
                    for (let i = 0; i < parsedTickets.length; i += 2) {
                        const oneWay = parsedTickets[i];
                        const returnTicketData = parsedTickets[i + 1];
                        let coupon
                        if (!confirmation) {
                            coupon = ReactDOMServer.renderToString(CouponSlip(
                                this.ticketLogo, this.silentPrint, this.printerSize, oneWay,
                            ));
                            coupon = coupon.replaceAll("replaceToqut", "''");
                            await handleSilentPrint(coupon, false);
                        }
                        let oneWayTicket = ReactDOMServer.renderToString(TicketSlip(oneWay,
                            services, false, advertisementTextTicket, confirmation, this.ticketLogo,
                            this.silentPrint, this.printerSize
                        ));
                        let ticket = oneWayTicket.replaceAll("replaceToqut", "''");
                        await handleSilentPrint(ticket, false);
                        let returnTicket = ReactDOMServer.renderToString(TicketSlip(returnTicketData,
                            services, false, advertisementTextTicket, confirmation, this.ticketLogo,
                            this.silentPrint, this.printerSize,maxDaysOfReturn
                        ));
                        returnTicket = returnTicket.replaceAll("replaceToqut", "''");
                        await handleSilentPrint(returnTicket, false);
                        if (!confirmation) {
                            coupon = ReactDOMServer.renderToString(CouponSlip(
                                this.ticketLogo, this.silentPrint, this.printerSize, returnTicketData,
                            ));
                            coupon = coupon.replaceAll("replaceToqut", "''");
                            await handleSilentPrint(coupon, false);
                        }
                    }
                } else {
                    for (let i = 0; i < parsedTickets.length; i++) {
                        if (!confirmation) {
                            let coupon = ReactDOMServer.renderToString(CouponSlip(
                                this.ticketLogo, this.silentPrint, this.printerSize, parsedTickets[i],
                            ));
                            coupon = coupon.replaceAll("replaceToqut", "''");
                            await handleSilentPrint(coupon, false);
                        }
                        let oneWayTicket = ReactDOMServer.renderToString(TicketSlip(parsedTickets[i],
                            services, false, advertisementTextTicket, confirmation, this.ticketLogo,
                            this.silentPrint, this.printerSize
                        ));
                        let ticket = oneWayTicket.replaceAll("replaceToqut", "''");
                        await handleSilentPrint(ticket, false);
                    }
                }
            } else {
                if (!confirmation) {
                    let coupon = ReactDOMServer.renderToString(CouponSlip(
                        this.ticketLogo, this.silentPrint, this.printerSize, parsedTickets[0]
                    ));
                    coupon = coupon.replaceAll("replaceToqut", "''");
                    await handleSilentPrint(coupon, false);
                }
                let ticketHtml = ReactDOMServer.renderToString(TicketSlip(
                    parsedTickets[0], services, false, advertisementTextTicket, confirmation, this.ticketLogo,
                    this.silentPrint, this.printerSize, maxDaysOfReturn
                ));
                ticketHtml = ticketHtml.replaceAll("replaceToqut", "''");
                await handleSilentPrint(ticketHtml, false);
            }
        } else {

            let html = "";

            let reorderedTickets = tickets;

            if (isReturnTicket && tickets.length > 1) {
                const mid = Math.ceil(tickets.length / 2);
                const firstHalf = tickets.slice(0, mid);
                const secondHalf = tickets.slice(mid);

                reorderedTickets = [];

                for (let i = 0; i < firstHalf.length; i++) {
                    reorderedTickets.push(firstHalf[i]);
                    if (secondHalf[i]) reorderedTickets.push(secondHalf[i]);
                }
            }
            for (const ticket of reorderedTickets) {
                if (!confirmation) {
                    html += ReactDOMServer.renderToString(CouponSlip(
                        this.ticketLogo, this.silentPrint, this.printerSize, ticket
                    ));
                }

                html += ReactDOMServer.renderToString(TicketSlip(
                    ticket, services, false, advertisementTextTicket, confirmation, this.ticketLogo,
                    this.silentPrint, this.printerSize, maxDaysOfReturn
                ));
            }

            html = html.replaceAll("replaceToqut", "''");
            await printHTML(html, false);

        }
    }

    printVerification = async (dataList) => {
        if (!this.printLogoTicket) {
            this.setTicketLogo(undefined);
        }

        const slipDataList = await Promise.all(
            dataList.map(async (data) => {
                const qrCodeUrl = await QRCode.toDataURL(`${data.ticketId}`);
                return {
                    ...data,
                    qrCodeUrl,
                    service: this.invoice.items[0],
                    ticketLogo: this.ticketLogo,
                };
            })
        );

        let html = ReactDOMServer.renderToString(
            VerificationSlip(slipDataList, this.silentPrint, this.printerSize)
        );

        html = html.replaceAll("replaceToqut", "''");

        if (this.silentPrint) {
            await handleSilentPrint(html, false);
        } else {
            await printHTML(html, false);
        }
    };


    printPeron = async (peronId, additional) => {
        if (!this.printLogoTicket) {
            this.setTicketLogo(undefined);
        }
        const qrCodeUrl = await QRCode.toDataURL(`${peronId}`);
        const html = ReactDOMServer.renderToString(PeronSlip(peronId, this.invoice.items[0], this.ticketLogo,
            qrCodeUrl, this.silentPrint, this.printerSize, additional));
        const newHtml = html.replaceAll("replaceToqut", "''");
        if (this.silentPrint) {
            await handleSilentPrint(newHtml, false);
        } else {
            await printHTML(newHtml, false);
        }
    }
}

const handleSendError = async (error) => {
    let parsed = error;
    if (error?.message === "7532" || error?.message === "7533") {
        parsed = "Račun je poslat u PU, ali greška prilikom štampanja";
    } else if (error?.message === "Bad Request") {
        parsed = "Došlo je do greške prilikom izdavanja računa";
    } else if (typeof error === "string") {
        parsed = error;
    } else if (typeof error?.message === "string") {
        parsed = error.message;
    }
    return parsed;
};

const validateBasicInvoice = (invoice) => {
    const requiredFields = ["transactionType", "cashier", "invoiceType", "payment", "items"];
    for (const requiredField of requiredFields) {
        if (!invoice[requiredField]) {
            throw new Error(`Field ${requiredField} is required!`);
        }
    }
    if (invoice.invoiceType === INVOICE_TYPE.copy || invoice.transactionType === TRANSACTION_TYPE.refund) {
        if (!invoice.referentDocumentNumber) {
            throw new Error(`Field referentDocumentNumber is required!`);
        }
        if (!invoice.referentDocumentDT) {
            throw new Error(`Field referentDocumentDT is required!`);
        }
    }
    let paymentsSum = invoice.payment.reduce((acc, {amount}) => acc + amount, 0);
    let itemsSum = invoice.items.reduce((acc, {totalAmount}) => acc + totalAmount, 0);
    if (paymentsSum !== itemsSum) {
        throw new Error("Ukupan iznos za placanje i ukupan iznos proizvoda se ne poklapa ");
    }
};

export const handleSilentPrint = async (html, isInvoice) => {
    let name = 'ticket.html'
    html = html.replaceAll("colSpan", "colspan");
    if (isInvoice) {
        html = html.replaceAll("class=\"dashedDivider\">",
            ">========================================");
        html = html.replaceAll("class=\"dashedDividerLine\">",
            ">-------------------------------------------------------------");
        name = 'invoice.html'
    }
    const blob = new Blob([html], {type: 'text/html'});
    const formData = new FormData();
    formData.append('file', blob, name);
    await printPdfLpfr(formData);
}
