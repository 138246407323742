import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import {
    finishTrafficLogParkingApi,
    insertOrUpdateTrafficLogAPI,
    insertOtherTrafficLogAPI,
    insertTrafficLogAPI, searchOtherTrafficLogsApi,
    searchTrafficLogsApi,
    searchTripWithTrafficLogApi,
    startTrafficLogParkingApi, updateOtherTrafficLogAPI,
    updateTrafficLogAPI
} from "../../api/trafficLog";
import {parseResponseError} from "../../utils/other";

export const fetchAllTrafficLogs =
    createAsyncThunk('fetchAllTrafficLogs', async (payload) => {
        const response = (await searchTripWithTrafficLogApi(payload)).data
        return response
            .sort((a, b) => (a.platformNumber ?? Infinity) - (b.platformNumber ?? Infinity))
            .map(row => ({
            ...row,
            isFirst: row.firstStationId === payload.stationId,
            isLast: row.lastStationId === payload.stationId,
        }))
    })

export const searchOtherTrafficLogs =
    createAsyncThunk('searchOtherTrafficLogs', async (payload) => {
        return (await searchOtherTrafficLogsApi(payload)).data
    })

export const searchTrafficLogs =
    createAsyncThunk('searchTrafficLogs', async (payload) => {
        return await searchTrafficLogsApi(payload)
    })

export const insertTrafficLog =
    createAsyncThunk('insertTrafficLog', async (payload, {rejectWithValue}) => {
        try {
            return (await insertTrafficLogAPI(payload)).data
        } catch (e) {
            return rejectWithValue(parseResponseError(e))
        }
    })

export const insertOtherTrafficLog =
    createAsyncThunk('insertOtherTrafficLog', async (payload, {rejectWithValue}) => {
        try {
            return (await insertOtherTrafficLogAPI(payload)).data
        } catch (e) {
            return rejectWithValue(parseResponseError(e))
        }
    })

export const updateOtherTrafficLog =
    createAsyncThunk('updateOtherTrafficLog', async (payload, {rejectWithValue}) => {
        try {
            return (await updateOtherTrafficLogAPI(payload)).data
        } catch (e) {
            return rejectWithValue(parseResponseError(e))
        }
    })

export const insertOrUpdateTrafficLog =
    createAsyncThunk('insertOrUpdateTrafficLog', async (payload, {rejectWithValue}) => {
        try {
            return (await insertOrUpdateTrafficLogAPI(payload)).data
        } catch (e) {
            return rejectWithValue(parseResponseError(e))
        }
    })

export const updateTrafficLog =
    createAsyncThunk('updateTrafficLog', async (payload, {rejectWithValue}) => {
        try {
            return (await updateTrafficLogAPI(payload)).data
        } catch (e) {
            return rejectWithValue(parseResponseError(e))
        }
    })

export const finishTrafficLogParking =
    createAsyncThunk('finishTrafficLogParking', async (payload, {rejectWithValue}) => {
        try {
            return (await finishTrafficLogParkingApi(payload)).data
        } catch (e) {
            return rejectWithValue(parseResponseError(e))
        }
    })

export const startTrafficLogParking =
    createAsyncThunk('startTrafficLogParking', async (payload, {rejectWithValue}) => {
        try {
            return (await startTrafficLogParkingApi(payload)).data
        } catch (e) {
            return rejectWithValue(parseResponseError(e))
        }
    })

const initialState = {
    trafficLogs: [],
    trafficLogsReport: [],
    loading: false,
    other: {
        content: [],
        totalElements: 0
    },
    loadingOther: false
}

export const trafficLogSlice = createSlice({
    name: 'trafficLogs',
    initialState,
    reducers: {},
    extraReducers: {
        [searchTrafficLogs.pending]: (state) => {
            state.loading = true;
        },
        [searchTrafficLogs.fulfilled]: (state, {payload}) => {
            state.trafficLogsReport = payload.data;
            state.loading = false;
        },
        [searchTrafficLogs.rejected]: (state) => {
            state.loading = false;
        },
        [fetchAllTrafficLogs.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllTrafficLogs.fulfilled]: (state, {payload}) => {
            state.trafficLogs = payload;
            state.loading = false;
        },
        [fetchAllTrafficLogs.rejected]: (state) => {
            state.loading = false;
        },
        [insertTrafficLog.pending]: (state) => {
            state.loading = true;
        },
        [insertTrafficLog.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.trafficLogs)];
            const index = arr.findIndex(p => p.tripId === payload.trip.id &&
                p.tripHasVehicleId === payload.expectedTripHasVehicleId);
            if (index !== -1) {
                const obj = {...arr[index]};
                setTrafficLogData(obj, payload);
                arr[index] = obj;
                state.trafficLogs = arr;
            }
            state.loading = false;
        },
        [insertTrafficLog.rejected]: (state) => {
            state.loading = false;
        },
        [insertOrUpdateTrafficLog.pending]: (state) => {
            state.loading = true;
        },
        [insertOrUpdateTrafficLog.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.trafficLogs)];
            const index = arr.findIndex(p => p.tripId === payload.trip.id &&
                p.tripHasVehicleId === payload.expectedTripHasVehicleId);
            if (index !== -1) {
                const obj = {...arr[index]};
                setTrafficLogData(obj, payload);
                arr[index] = obj;
                state.trafficLogs = arr;
            }
            state.loading = false;
        },
        [insertOrUpdateTrafficLog.rejected]: (state) => {
            state.loading = false;
        },
        [updateTrafficLog.pending]: (state) => {
            state.loading = true;
        },
        [updateTrafficLog.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.trafficLogs)];
            const index = arr.findIndex(p => p.tripId === payload.trip.id &&
                p.tripHasVehicleId === payload.expectedTripHasVehicleId);
            if (index !== -1) {
                const obj = {...arr[index]};
                setTrafficLogData(obj, payload);
                arr[index] = obj;
                state.trafficLogs = arr;
            }
            state.loading = false;
        },
        [updateTrafficLog.rejected]: (state) => {
            state.loading = false;
        },
        [finishTrafficLogParking.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.trafficLogs)];
            const index = arr.findIndex(p => p.trafficLogId === payload.id);
            if (index !== -1) {
                const obj = {...arr[index]};
                setTrafficLogData(obj, payload);
                arr[index] = obj;
                state.trafficLogs = arr;
            }
            state.loading = false;
        },
        [startTrafficLogParking.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.trafficLogs)];
            const index = arr.findIndex(p => p.tripId === payload.trip.id &&
                p.tripHasVehicleId === payload.expectedTripHasVehicleId);
            if (index !== -1) {
                const obj = {...arr[index]};
                setTrafficLogData(obj, payload);
                arr[index] = obj;
                state.trafficLogs = arr;
            }
            state.loading = false;
        },
        [searchOtherTrafficLogs.pending]: (state) => {
            state.loadingOther = true;
        },
        [searchOtherTrafficLogs.fulfilled]: (state, {payload}) => {
            state.other = payload;
            state.loadingOther = false;
        },
        [searchOtherTrafficLogs.rejected]: (state) => {
            state.loadingOther = false;
        },
    }
});

const setTrafficLogData = (obj, response) => {
    obj.trafficLogId = response.id;
    obj.tDepartureTime = response.departureTime;
    obj.tArrivalTime = response.arrivalTime;
    obj.departureVehicleId = response.depVehicleId;
    obj.arrivalVehicleId = response.arrVehicleId;
    obj.tArrivalTime = response.arrivalTime;
    obj.note = response.note;
    if (response.arrVehicle) {
        obj.arrVehicle = response.arrVehicle;
        obj.arrPlateNumber = response.arrVehicle.plateNumber;
        obj.arrivalVehicleId = response.arrVehicle.id;
    }
    if (response.depVehicle) {
        obj.depVehicle = response.depVehicle;
        obj.depPlateNumber = response.depVehicle.plateNumber;
        obj.departureVehicleId = response.depVehicle.id;
    }
    if (response?.realOperator) {
        obj.realOperatorName = response?.realOperator?.name;
        obj.realOperatorId = response?.realOperator?.id;
    }
}

export const trafficLogsReducer = trafficLogSlice.reducer
