import Dexie from "dexie";
import {getSessionAppVersion, getSessionOperator} from "../../helper/session";
import moment from "moment";
import {INVOICE_TYPE, TRANSACTION_TYPE} from "../../constants";
import {get_browser, sortByDateDescending} from "../../helper/other";
import {doc, setDoc} from "firebase/firestore";
import {AUTH, DB} from "../../auth/FirebaseContext";
import {getInvoicesByUser, getInvoicesByUserForPeriod} from "../../helper";

export const offlineDB = new Dexie("ESIR_BUS_V2");
export const offline_tables = {
    all_refunded_invoices: 'all_refunded_invoices',
    all_advance_invoices: 'all_advance_invoices',
    all_accounting_invoices: 'all_accounting_invoices',
    all_normal_invoices: 'all_normal_invoices',
    all_tickets: 'all_tickets',
    all_copies_invoices: 'all_copies_invoices',
    all_training_invoices: 'all_training_invoices',
    all_unhandled_invoices: 'all_unhandled_invoices',
    all_advance_open_invoices: 'all_advance_open_invoices',
    all_advance_old_invoices: 'all_advance_old_invoices',
    all_errors: 'all_errors',
    checks: 'checks',
    items: 'items',
    customers: 'customers',
    operators: 'operators',
    settings: 'settings',
}

export const createDB = () => {
    offlineDB.version(1).stores({
        all_refunded_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,       
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
        all_advance_invoices: `
       invoiceNumber,
       userLocation,
       userUid,
        buyerCostCenter,
        buyerTin,
        cashier,       
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
        all_accounting_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,       
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
        all_normal_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
        all_tickets: `
        timestamp,
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
        all_copies_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
        all_training_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,        
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
        all_unhandled_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,       
        invoiceType,
        items,
        paymentMethod,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
        all_advance_open_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        advanceAmount,     
        items,
        linkedInvoices,
        linkedRefund,
        timestamp,
        isInsertedOffline
        `,
        all_advance_old_invoices: `
        invoiceNumber,
        userLocation,
        userUid,
        buyerCostCenter,
        buyerTin,
        cashier,
        dateAndTimeOfIssue,
        internalData,        
        invoiceType,
        items,
        paymentMethod,
        referentAction,
        referentDocumentDT,
        referentDocumentNumber,
        sdcDateTime,
        taxItems,
        totalAmount,
        transactionType,
        isInsertedOffline
        `,
        all_errors: `
        ++id,
        userUid,
        method_name,
        file_name,
        params,
        error`,

        checks: `
        uid,
        userUid,
        price,
        qty,
        date,
        object`,

        items: `
        uid,
        userUid,
        image,
        ingredients,
        quantity,
        name,
        category,
        vat,
        unit,
        ean,
        price,
        code,
        isInsertedOffline`,

        customers: `
        uid,
        userUid,
        name,
        pib,
        mb,
        address,
        city,
        telephone,
        email,
        contactPerson,
        isInsertedOffline`,

        operators: `
        uid,
        userUid,
        group,
        secret,
        username,
        isInsertedOffline`,

        settings: `
        accounting,
        userUid,
        advance,
        articles,
        card,
        cash,
        change,
        theme,
        virman,
        isInsertedOffline`
    });
    offlineDB.open()
}

// -------------- INVOICES -------------------
const handleInvoiceType = (invoice) => {
    if (invoice.transactionType === TRANSACTION_TYPE.refund) {
        if (invoice.invoiceType !== INVOICE_TYPE.copy && invoice.invoiceType !== INVOICE_TYPE.proforma && invoice.invoiceType !==
            INVOICE_TYPE.training) {
            return offline_tables.all_refunded_invoices;
        }
    }
    switch (invoice.invoiceType) {
        case INVOICE_TYPE.normal:
            if (AUTH.currentUser.email === 'graduzice@becejprevoz.com') {
                return offline_tables.all_tickets
            }
            return offline_tables.all_normal_invoices
        case INVOICE_TYPE.proforma:
            return offline_tables.all_accounting_invoices
        case INVOICE_TYPE.copy:
            return offline_tables.all_copies_invoices
        case INVOICE_TYPE.training:
            return offline_tables.all_training_invoices
        case INVOICE_TYPE.advance:
            return offline_tables.all_advance_invoices
        default:
            return offline_tables.all_unhandled_invoices
    }
};

export async function putInvoiceOffline(invoice) {
    if (invoice && AUTH.currentUser) {
        try {
            let tableName = handleInvoiceType(invoice);
            offlineDB.open().then(async db => {
                const table = db.table(tableName)
                if (table) {
                    let splitInvoiceNumber = [
                        "", ""
                    ]
                    if (invoice.invoiceNumber) {
                        splitInvoiceNumber = invoice.invoiceNumber.split('-')
                    } else {
                        console.error(invoice, " ||| ||")
                    }

                    let locationUserUid = splitInvoiceNumber[0] + AUTH.currentUser.uid
                    let exist = AUTH.currentUser.email === 'graduzice@becejprevoz.com' ?
                        await table.where('timestamp').equals(invoice.timestamp).first() :
                        await table.where('invoiceNumber').equals(invoice.invoiceNumber).first()
                    if (!exist) {
                        table.put({
                            ...invoice,
                            userUid: AUTH.currentUser.uid,
                            userLocation: locationUserUid
                        })
                    }
                }
            })
        } catch (e) {
            console.error("putInvoiceOffline", e)
        }
    }
}

export async function getLastInsertedOfflineInvoice() {
    try {
        return offlineDB.open().then(async db => {
            let tables = [
                offline_tables.all_accounting_invoices,
                offline_tables.all_refunded_invoices,
                offline_tables.all_normal_invoices,
                offline_tables.all_training_invoices,
                offline_tables.all_copies_invoices,
                offline_tables.all_advance_invoices,
                offline_tables.all_tickets]
            let lastInvoice;
            for (const table1 of db.tables) {
                if (tables.includes(table1.name)) {
                    let invoice = await table1.orderBy('sdcDateTime')
                        .filter((item) => item.userUid === AUTH.currentUser.uid && item.isInsertedOffline === false)
                        .reverse().first()
                    if (!lastInvoice) {
                        lastInvoice = invoice;
                    } else {
                        if (invoice) {
                            if (moment(invoice.sdcDateTime).isAfter(moment(lastInvoice.sdcDateTime))) {
                                lastInvoice = invoice
                            }
                        }
                    }
                }
            }
            return lastInvoice;
        })
    } catch (e) {
        console.error("getLastInsertedOfflineInvoice", e)
        return undefined;
    }
}

export async function getFirstInsertedOfflineInvoice() {
    try {
        return offlineDB.open().then(async db => {
            let tables = [
                offline_tables.all_accounting_invoices,
                offline_tables.all_refunded_invoices,
                offline_tables.all_normal_invoices,
                offline_tables.all_training_invoices,
                offline_tables.all_copies_invoices,
                offline_tables.all_advance_invoices,
                offline_tables.all_tickets]
            let firstInvoice;
            for (const table1 of db.tables) {
                if (tables.includes(table1.name)) {
                    let invoice = await table1.orderBy('sdcDateTime')
                        .filter((item) => item.userUid === AUTH.currentUser.uid && item.isInsertedOffline === false)
                        .first()
                    if (!firstInvoice) {
                        firstInvoice = invoice;
                    } else {
                        if (invoice) {
                            if (moment(invoice.sdcDateTime).isBefore(moment(firstInvoice.sdcDateTime))) {
                                firstInvoice = invoice
                            }
                        }
                    }
                }
            }
            return firstInvoice;
        })
    } catch (e) {
        console.error("getLastInsertedOfflineInvoice", e)
        return undefined;
    }
}

export async function getRefundedOfflineInvoiceByReferentDocumentNumber(referentDocumentNumber) {
    return offlineDB.open().then(async db => {
        let table = db.table(offline_tables.all_refunded_invoices);
        return table.where("referentDocumentNumber").equalsIgnoreCase(referentDocumentNumber).first();
    });
}

export async function getAllRefundedOfflineInvoiceByReferentDocumentNumber(referentDocumentNumber) {
    try {
        const db = await offlineDB.open();
        const table = db.table(offline_tables.all_refunded_invoices);
        return await table.where("referentDocumentNumber").equalsIgnoreCase(referentDocumentNumber).toArray();
    } catch (error) {
        console.error("Error fetching refunded invoices:", error);
        throw error;
    }
}


export async function getAllOfflineInvoiceForReportByItemName(location, itemName) {
    return offlineDB.open().then(async db => {
        let tables = [offline_tables.all_refunded_invoices, offline_tables.all_normal_invoices,
            offline_tables.all_advance_invoices]
        let all = [];
        for (const table1 of db.tables) {
            if (tables.includes(table1.name)) {
                let invoices;
                if (location) {
                    invoices = await table1.filter(invoice => {
                        return invoice.userUid === AUTH.currentUser.uid &&
                            invoice.invoiceNumber.startsWith(location) &&
                            invoice.items.some(item => item.name.startsWith(itemName))
                    }).toArray()
                } else {
                    invoices = await table1.filter(invoice => {
                        return invoice.userUid === AUTH.currentUser.uid &&
                            invoice.items.some(item => item.name.startsWith(itemName))
                    }).toArray()
                }
                all = [...all, ...invoices]
            }
        }
        return all;
    });
}

//---ITEMS---
export async function putItemOffline(item) {
    return offlineDB.open().then(db => {
        const table = db.table(offline_tables.items);
        try {
            offlineDB.open().then(async _ => {
                if (table) {
                    let exists = await table.where('uid').equals(item.uid).first()
                    if (!exists) {
                        table.put({
                            ...item,
                            userUid: AUTH.currentUser.uid
                        });
                    }
                }
            })
        } catch (e) {
            console.error(e, 'putItemOffline a')
        }
    })
}

export async function putItemsOffline(items = []) {
    return offlineDB.open().then(async db => {
        const table = db.table(offline_tables.items);
        if (table) {
            for (const item of items) {
                table.put({
                    ...item,
                    userUid: AUTH.currentUser.uid
                })
            }
        }
    });
}

// OPERATERI
export async function putOperatorsOffline(operators = []) {
    return offlineDB.open().then(db => {
        const table = db.table(offline_tables.operators);
        if (table) {
            operators.forEach(item => {
                table.put({
                    ...item,
                    userUid: AUTH.currentUser.uid
                })
            })
        }
    });
}

export async function getOfflineInvoiceByInvoiceNumber(invoiceNumber) {
    return offlineDB.open().then(async db => {
        let tables = [offline_tables.all_accounting_invoices, offline_tables.all_refunded_invoices,
            offline_tables.all_normal_invoices, offline_tables.all_training_invoices,
            offline_tables.all_copies_invoices];
        for (const table1 of db.tables) {
            if (tables.includes(table1.name)) {
                let invoice = await table1.where("invoiceNumber").equals(invoiceNumber).first();
                if (invoice) {
                    return invoice;
                }
            }
        }
        return undefined;
    });
}

export async function getAllOfflineInvoicesFromTables() {
    try {
        const db = await offlineDB.open();
        let tables = [
            offline_tables.all_accounting_invoices,
            offline_tables.all_normal_invoices,
            offline_tables.all_training_invoices,
            offline_tables.all_copies_invoices,
            offline_tables.all_refunded_invoices
        ];

        const arr = [];

        for (const table1 of db.tables) {
            if (tables.includes(table1.name)) {
                let invoice = await table1.toArray();
                arr.push(invoice);
            }
        }

        const hash = {};
        const merged = [];

        arr.forEach((arr) => {
            arr.forEach((data) => {
                if (!hash[data.invoiceNumber]) {
                    hash[data.invoiceNumber] = true;
                    merged.push(data);
                }
            });
        });

        return sortByDateDescending(merged);
    } catch (e) {
        console.error("getLastInsertedOfflineInvoice", e);
        return undefined;
    }
}

export async function extractAllItemsWithUidFromOfflineInvoices() {
    try {
        const db = await offlineDB.open();
        const tables = [
            offline_tables.all_accounting_invoices,
            offline_tables.all_normal_invoices,
            offline_tables.all_training_invoices,
            offline_tables.all_copies_invoices,
            offline_tables.all_refunded_invoices
        ];

        const result = [];

        function calculatePDV(totalAmount, taxItems, label, quantity) {
            const tax = taxItems?.find(t => t.label === label);
            return tax ? extractPDV(totalAmount / quantity, tax.rate) : { base: 0, pdv: 0 };
        }

        function extractPDV(totalAmount, rate) {
            const base = totalAmount / (1 + rate / 100);
            const pdv = totalAmount - base;

            return {
                base: parseFloat(base.toFixed(2)),
                pdv: parseFloat(pdv.toFixed(2))
            };
        }



        for (const table of db.tables) {
            if (tables.includes(table.name)) {
                const invoices = await table.toArray();
                for (const invoice of invoices) {
                    if (!invoice || !Array.isArray(invoice.items)) continue;

                    invoice.items
                        .filter(item => !!item.uid)
                        .forEach((item, index) => {
                            result.push({
                                id: `${invoice.invoiceNumber}-${index}`,
                                name: item.name,
                                invoiceNumber: invoice.invoiceNumber,
                                cashier: invoice.cashier,
                                saleDt: invoice.sdcDateTime || null,
                                price: item.unitPrice,
                                baseAndPDV: calculatePDV(item.totalAmount, invoice.taxItems, item.labels?.[0], item.quantity),
                                quantity: item.quantity,
                                totalAmount: item.totalAmount,
                                invoiceType: invoice.invoiceType,
                                transactionType: invoice.transactionType
                            });
                        });
                }
            }
        }

        return sortByDateDescending(result).reverse();
    } catch (e) {
        console.error("extractAllItemsWithUidFromOfflineInvoices", e);
        return [];
    }
}

// ------------ ERROR & LOGS ------------
export function storeErrorToOffline(method_name, file_name, params = [], error) {
    try {
        let stringParams = "";
        params.forEach(value => {
            stringParams = stringParams + JSON.stringify(value);
        });
        putLogToFirebase(method_name, file_name, stringParams, error);
    } catch (e) {
    }
}

export function putLogToFirebase(method_name, file_name, params, error) {
    let browser = get_browser();
    console.debug("-----------  putLogToFirebase  --------------");
    console.debug("method_name", method_name);
    console.debug("file_name", file_name);
    console.debug("params", params);
    console.debug("error", error);
    console.debug("browser", browser);
    console.debug("-------------------------");

    let obj = {
        cashier: getSessionOperator().username,
        application: "webBUS",
        version: getSessionAppVersion(),
        params: params || null,
        fileName: file_name || null,
        methodName: method_name ? method_name : null,
        error: error ? error : null,
        browser: browser || null
    };
    const colref = doc(DB, "log", "users", AUTH.currentUser.uid, Date.now().toString());
    setDoc(colref, obj).then(_ => {
        console.debug("Uspesno sacuvan LOG na firestoru");
    }).catch(_ => {
        try {
            return offlineDB.open().then(db => {
                const table = db.table("all_errors");
                if (table) {
                    return table.add({
                        method_name: method_name,
                        file_name: file_name,
                        params: params,
                        error: error
                    }, {});
                }
            });
        } catch (e) {

        }
    });
}

export async function insertAllUserInvoicesOffline() {

    const currentDate = moment();
    const firstDayOfPreviousMonth = moment(currentDate).subtract(1, 'month').startOf('month');

    const fDayOfPrevMonth = firstDayOfPreviousMonth.format('YYYY-MM-DD');

    return new Promise(async (resolve, reject) => {
        await getInvoicesByUser(null, [], resolve, reject, fDayOfPrevMonth);
    });
}

export async function insertAllUserInvoicesOfflineForPeriod(period) {
    return new Promise(async (resolve, reject) => {
        await getInvoicesByUserForPeriod([], resolve, reject, period);
    });
}

export function saveInvoiceOffline(check) {
    return offlineDB.open().then(db => {
        const table = db.table(offline_tables.checks);
        if (table) {
            return table.put({
                ...check,
                userUid: AUTH.currentUser.uid
            });
        }
    });
}
