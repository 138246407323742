import {
    Box,
    CircularProgress,
    Divider,
    Drawer,
    IconButton,
    ListItemButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import {NAV} from "../../../config";
import Iconify from "../../../components/Iconify";
import Scrollbar from "../../../components/Scrollbar";
import {useLayoutEffect, useState} from "react";
import {dispatch, useSelector} from "../../../redux/store";

import {fCurrency} from "../../../utils/formatNumber";
import {RHFTextField} from "../../../components/hook-form";
import {useWatch} from "react-hook-form";
import {fetchAllMergedItems} from "../../../redux/slices/items";

const PADDING = 2;

export default function ItemsDrawer({open, index, onClose, onSelectEvent, control}) {
    const {allItemsList, loading} = useSelector(state => state.items)

    const v = useWatch({
        control,
        name: 'selectedItems'
    })
    const dataFiltered = applyFilter({
        inputData: allItemsList.filter(i => i.name !== undefined),
        filterName: v[index].name,
    })
    const [openAddDialog, setOpenAddDialog] = useState(false);

    useLayoutEffect(() => {
        dispatch(fetchAllMergedItems())
    }, [])

    const handleOpen = () => {
        setOpenAddDialog(true);
    };


    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            BackdropProps={{
                invisible: true,
            }}
            PaperProps={{
                sx: {width: NAV.W_BASE},
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{pl: 2, pr: 1, py: 2}}>
                <Typography variant="subtitle1">Usluge</Typography>

                <Tooltip title="Uslugu">
                    <IconButton onClick={handleOpen}>
                        <Iconify icon="material-symbols:add-box-outline-rounded"/>
                    </IconButton>
                </Tooltip>
            </Stack>

            <Divider/>

            <Typography
                variant="caption"
                sx={{
                    p: PADDING,
                    color: 'text.secondary',
                    fontWeight: 'fontWeightMedium',
                }}
            >
                Pretraži
            </Typography>

            <div style={{padding: 10}}>
                <RHFTextField size="small"
                              name={`items[${index}].name`}
                              label="Naziv usluge"
                              InputLabelProps={{shrink: true}}
                />
            </div>
            <Scrollbar sx={{height: 1, mt: 1}}>
                {loading ?
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <CircularProgress size={45}/>
                    </Box>
                    : <>
                        {dataFiltered.slice(0, 10).map((item, indexItem) => (
                            <ListItemButton key={`data-${indexItem}`} onClick={() => onSelectEvent(item, index)}>
                                <Box
                                    sx={{
                                        top: 0,
                                        left: 0,
                                        width: 0,
                                        height: 0,
                                        position: 'absolute',
                                        borderRight: '8px solid transparent',
                                        borderTop: `8px solid grey`,
                                    }}
                                />

                                <Stack flex={1} spacing={0.5} direction={'column'}
                                       divider={<Divider orientation="horizontal" flexItem/>}>
                                    <Typography variant="subtitle2"
                                                sx={{fontSize: 13, mt: 0.5}}>{item.name}</Typography>
                                    <Typography variant="subtitle2" component="div"
                                                sx={{
                                                    fontSize: 11,
                                                    color: 'text.disabled'
                                                }}>Šifra: {item.code}</Typography>
                                    <Typography variant="subtitle2" component="div" sx={{
                                        fontSize: 11,
                                        color: 'text.disabled'
                                    }}> Kategorija: {item.category}
                                    </Typography>
                                    <Typography variant="subtitle2" component="div"
                                                sx={{fontSize: 11, color: 'text.disabled'}}>Jedinica
                                        mere: {item.unit}
                                    </Typography>
                                    <Typography variant="subtitle2" component="div"
                                                sx={{fontSize: 11, color: 'text.disabled'}}>
                                        Poreska stopa: {item?.vat}
                                    </Typography>
                                    <Typography variant="subtitle2" component="div"
                                                sx={{fontSize: 13}}>Cena: {fCurrency(item?.price?.toString())} RSD
                                    </Typography>
                                </Stack>
                            </ListItemButton>))}
                    </>}

            </Scrollbar>

            {/*{openAddDialog && <ProductAddDialog*/}
            {/*    open={openAddDialog}*/}
            {/*    onClose={handleClose}*/}
            {/*/>}*/}
        </Drawer>
    )
}


// --------------------------------------

function applyFilter({
                         inputData,
                         filterName
                     }) {
    if (filterName) {
        inputData = inputData.filter(
            (item) =>
                item?.code?.toString().indexOf(filterName.toLowerCase()) !== -1 ||
                item?.name?.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
        );
    }

    return inputData;
}
