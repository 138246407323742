import {Divider, Stack, Typography} from "@mui/material";
import {fCurrency} from "../../../utils/formatNumber";
import {useFormContext, useWatch} from "react-hook-form";
import {useSelector} from "../../../redux/store";
import {invoiceItemsForShow, invoiceTotalAmount} from "../../../helper/invoice/allCalculations";
import {useEffect} from "react";

export default function AmountDetails({directionType, state}) {
    const {control,setValue} = useFormContext();
    const {currentTaxRates} = useSelector(((state) => state.taxCore));
    const services = useWatch({
        control,
        name: 'services'
    })

    const defaultServices = useWatch({
        control,
        name: 'defaultServices'
    })

    const actions = useWatch({
        control,
        name: 'actions'
    })

    const passengers = useWatch({
        control,
        name: 'passengers'
    })

    const calculated = invoiceTotalAmount({
        price: state.priceOneWay,
        returnPrice:state.priceReturnTicket,
        priceMonthly:state.priceMonthly,
        passengers: passengers,
        saleAction: actions[0], // ovo treba da bude objekat jer samo jedna moze biti
        //services: services?.length > 0 ? services : [defaultServices],
        services: services,
        directionType: directionType,
        currentTaxRates: currentTaxRates,
        externalSale: state?.externalSale
    })

    const resultItems = invoiceItemsForShow ({
        price: state.priceOneWay,
        returnPrice:state.priceReturnTicket,
        priceMonthly:state.priceMonthly,
        passengers: passengers,
        directionType: directionType,
        externalSale: state?.externalSale
    })

    return (
        <Stack spacing={2} justifyContent="flex-end" width={1} sx={{mt: 3}}>
            {[...services].map(item =>
                <Stack key={item.uid} direction="row" justifyContent="flex-end">
                    <Typography variant="subtitle1">
                        {item.name}:
                    </Typography>
                    <Typography sx={{textAlign: 'right', width: 100}}>
                        {passengers.length} x {fCurrency(item.price.toString())}
                    </Typography>
                </Stack>
            )}
            {actions[0] &&
                <Stack direction="row" justifyContent="flex-end">
                    <Typography variant="subtitle1">
                        {actions[0]?.salesActionType?.name}:
                    </Typography>
                    <Typography sx={{textAlign: 'right', width: 100}}>
                        {passengers.length} x {fCurrency(actions[0]?.amount.toString())}
                    </Typography>
                </Stack>
            }
            <Divider/>

            {resultItems && resultItems?.map(item => (
                <Stack direction="row" justifyContent="flex-end">
                    <Typography variant="subtitle1">{item?.name}:</Typography>
                    <Typography sx={{textAlign: 'right', width: 100}}>
                         {item.quantity} x {fCurrency(item?.price.toString())}
                    </Typography>
                </Stack>
            ))}
            {/*<Stack direction="row" justifyContent="flex-end">*/}
            {/*    <Typography variant="subtitle1">Osnovica:</Typography>*/}
            {/*    <Typography sx={{textAlign: 'right', width: 100}}>*/}
            {/*        {fCurrency(calculated.baseAmount.toString())}*/}
            {/*    </Typography>*/}
            {/*</Stack>*/}

            {/*<Stack direction="row" justifyContent="flex-end">*/}
            {/*    <Typography variant="subtitle1">PDV:</Typography>*/}
            {/*    <Typography sx={{textAlign: 'right', width: 100}}>*/}
            {/*        {fCurrency(calculated.pdv.toString())}*/}
            {/*    </Typography>*/}
            {/*</Stack>*/}

            {/*<Stack direction="row" justifyContent="flex-end">*/}
            {/*    <Typography variant="subtitle1" sx={{color: "#00AB55"}}>*/}
            {/*        Popust:*/}
            {/*    </Typography>*/}
            {/*    <Typography sx={{textAlign: 'right', width: 100, color: "#00AB55"}}>*/}
            {/*        {fCurrency(calculated.discount.toString())}*/}
            {/*    </Typography>*/}
            {/*</Stack>*/}

            {/*<Stack direction="row" justifyContent="flex-end">*/}
            {/*    <Typography variant="subtitle1" sx={{color: "#00AB55"}}>*/}
            {/*        Cena:*/}
            {/*    </Typography>*/}
            {/*    <Typography sx={{textAlign: 'right', width: 100, color: "#00AB55"}}>*/}
            {/*        {passengers.length} x  {fCurrency(calculated.unitPrice.toString())}*/}
            {/*    </Typography>*/}
            {/*</Stack>*/}

            <Divider/>

            <Stack direction="row" justifyContent="flex-end">
                <Typography variant="h6">Ukupno za uplatu :</Typography>
                <Typography variant="h6" sx={{fontWeight: 700, textAlign: 'right', width: 140}}>
                    {fCurrency(calculated.unitPrice.toString())}
                </Typography>
            </Stack>
        </Stack>
    )
}

