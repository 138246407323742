import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getAllVATS_API} from "../../api/firebase";

export const getAllVATS =
    createAsyncThunk('getAllVATS', async (params) => {
        return getAllVATS_API()
    })


const initialState = {
    foundAllVats: [],
    loadingVats: false,
}

const firebaseSlice = createSlice({
    name: 'firebaseBE',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllVATS.pending, (state) => {
            state.loadingVats = true;
        })
            .addCase(getAllVATS.fulfilled, (state, {payload}) => {
                state.foundAllVats = payload.data;
                state.loadingVats = false;
            })
            .addCase(getAllVATS.rejected, (state) => {
                state.loadingOperatorsBe = false;
            })
    }
});

export default firebaseSlice.reducer;
