import {Skeleton, Stack, Tooltip, Typography} from "@mui/material";
import Iconify from "../../components/Iconify";
import {nameSlice} from "../../helper/other";

const canPreview = [0, 2];

export default function Comments({routeComment, commentInternal, allowedViewers = [], loading}) {

    if (loading) {
        return <Skeleton variant="text"/>
    }

    return (
        <>
            {commentInternal && <Tooltip title={commentInternal}>
                <Stack  direction="row" spacing={1} justifyContent="center" alignItems={"center"}
                       width={1}>
                    <Typography variant="subtitle2" color="orangered">
                        {nameSlice(commentInternal, 20)}
                    </Typography>
                    <Iconify icon={'ph:info-duotone'} width={16}/>
                </Stack>
            </Tooltip>}
            {routeComment ?
                <Tooltip title={routeComment}>
                    <Stack key={routeComment} direction="row" spacing={1} justifyContent="center" alignItems={"center"}
                           width={1}>
                        <Typography variant="subtitle2" color="orangered">
                            {nameSlice(routeComment, 20)}
                        </Typography>
                        <Iconify icon={'ph:info-duotone'} width={16}/>
                    </Stack>
                </Tooltip>
                :
                <></>
            }
        </>
    )
}
