import {customAxiosBE} from "../config";
import moment from "moment/moment";

export async function getOperatorIncomeApi(data){
    return customAxiosBE.post(`/report/operator-income`, {
        ...data,
        dateFrom: moment(data.dateFrom).startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
        dateTo: moment(data.dateTo).startOf("day").format("YYYY-MM-DDTHH:mm:ss")
    })
}

export async function getTicketsReportApi(data){
    return customAxiosBE.post(`/report/tickets-report`, data)
}

export async function getCommissionsReportApi(data, signal){
    return customAxiosBE.post(`/report/commission`, {
        ...data
    }, signal)
}

export async function getOperatorSalesReportApi(data) {
    return customAxiosBE.post(`/report/operator-sales`, data);
}

