import {
    Dialog,
    DialogContent,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import * as React from "react";
import {useLayoutEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {useLocales} from "../../../locales";
import {getPricePreviewByLineIdRateCardId} from "../../../api/trip";
import {fCurrency} from "../../../utils/formatNumber";
import Iconify from "../../../components/Iconify";
import {getAllTicketsTypeByOperatorIdAPI} from "../../../api/ticketType/ticketType";
import PriceChangeDialog from "./PriceChangeDialog";
import {findAllRateCardsByTripId} from "../../../api/rateCard";
import moment from "moment";

export default function PriceListDialog({data, onClose}) {
    const {enqueueSnackbar} = useSnackbar();
    const {translate} = useLocales();
    const [lastChangedField, setLastChangedField] = useState(null);

    const [loading, setLoading] = useState(false);
    const [pricePreviewData, setPricePreviewData] = useState([]);
    const [discountData, setDiscountData] = useState([]);
    const [rateCards, setRateCards] = useState([]);
    const [selectedRateCard, setSelectedRateCard] = useState();
    const [openDialog, setOpenDialog] = useState({
        open: false, data: null
    });

    const [refresh, setRefresh] = useState(false);
    useLayoutEffect(() => {
        findAllRateCardsByTripId(data?.tripId).then((response) => {
            if (response.data) {
                if(!data?.showRateCardSelect){
                    if(!data?.journeyDate){
                        return enqueueSnackbar("Cenovnik nema validan datum")
                    }
                    const formatedJD = data?.journeyDate ? moment(data.journeyDate).startOf('day') :   moment().startOf('day');
                    const firstValidRateCard  = findFirstValidRateCard(response.data, formatedJD);
                    handlePrices(firstValidRateCard?.id, data?.journeyDate)
                } else {
                    setRateCards(response.data);
                }
            }
        }).catch(e => {
            console.error(e, "findAllRateCardsByTripId")
        })
    }, []);

    function findFirstValidRateCard(data,journeyDate) {

        return data.find(item => {
            const validFrom = moment(item.validFrom, 'YYYY-MM-DD').startOf('day');
            const validTo = item.validTo ? moment(item.validTo, 'YYYY-MM-DD').startOf('day') : null;

            if (validTo) {
                return journeyDate.isSameOrAfter(validFrom) && journeyDate.isBefore(validTo);
            }

            return journeyDate.isSameOrAfter(validFrom);
        });
    }

    const triggerRefresh = () => {
        setRefresh(prev => !prev);
    }
    const applyDiscounts = (price, row, key, index) => {
        const {applyDiscountReturnTicket, applyDiscountOneWay, applyRoundPrice, id} = row;
        const foundException = pricePreviewData[index].priceExceptionsList.find(item => item.ticketTypeId == id)
        let adjustedPrice = price;
        let discountType = "NONE";
        if (foundException) {
            const exceptionPrice = foundException[key];
            if (exceptionPrice !== undefined) {
                adjustedPrice = exceptionPrice;
                discountType = "EXCEPTION";
            } else {
                console.error("Exception price not found for key:", key, "in foundException:", foundException);
            }
        } else {
            if ((key === "priceOneWay" && applyDiscountOneWay) || (key === "returnTicketPrice" && applyDiscountReturnTicket)) {
                if (key === "priceOneWay") {
                    if (row.discountPCT > 0) {
                        adjustedPrice = price - (price * row.discountPCT / 100);
                        discountType = "PCT";
                    } else if (row.discountABS > 0) {
                        adjustedPrice = price - row.discountABS;
                        discountType = "ABS";
                    }
                } else {
                    if (row.discountPCTReturn > 0) {
                        adjustedPrice = price - (price * row.discountPCTReturn / 100);
                        discountType = "PCT";
                    } else if (row.discountABSReturn > 0) {
                        adjustedPrice = price - row.discountABSReturn;
                        discountType = "ABS";
                    }
                }
            }

            if (applyRoundPrice) {
                adjustedPrice = Math.ceil(adjustedPrice / 10) * 10;
            }

        }
        return {adjustedPrice, discountType};
    };


    const renderTableRows = () => {
        const rows = [
            {label: "JEDAN SMER", key: "priceOneWay"},
            {label: "POVRATNA CENA", key: "returnTicketPrice"},
            {label: "DNEVNA/MESEČNA", key: "priceMonthly"},
        ];

        discountData.forEach((discount, i) => {
            // Provera za prikaz "JEDAN SMER"
            if (discount.applyDiscountOneWay) {
                rows.push({
                    label: `JEDAN SMER (${discount.name})`,
                    key: "priceOneWay",
                    discount: discount
                });
            }


            if (discount.applyDiscountReturnTicket) {
                rows.push({
                    label: `POVRATNA CENA (${discount.name})`,
                    key: "returnTicketPrice",
                    discount: discount
                });
            }

        });
        console.log(pricePreviewData, "pricePreviewDatapricePreviewData")
        return rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
                <TableCell
                    sx={{
                        maxWidth: 200,
                        border: 1,
                        background: "#F4F6F8",
                        borderColor: 'divider',
                        fontSize: '0.775rem',
                        padding: '2px',
                        position: 'sticky',
                        left: 0,
                        zIndex: 1,
                    }}>
                    {row.label}
                </TableCell>
                {pricePreviewData.map((item, index) => (
                    <TableCell
                        key={index}
                        align="center"
                        sx={{
                            border: 1,
                            fontSize: '0.875rem',
                            padding: '4px',
                            backgroundColor:
                                lastChangedField &&
                                lastChangedField.stationId === item.stationId &&
                                lastChangedField.ticketTypeId === row?.discount?.id &&
                                lastChangedField.key === row.key
                                    ? 'rgba(0, 128, 0, 0.1)'
                                    : 'transparent',
                            borderColor:
                                lastChangedField &&
                                lastChangedField.stationId === item.stationId &&
                                lastChangedField.ticketTypeId === row?.discount?.id &&
                                lastChangedField.key === row.key
                                    ? 'green'
                                    : 'divider',
                        }}
                    >
                        <Stack direction="row" justifyContent="space-between">
                            {row.discount
                                ? applyDiscounts(item[row.key], row.discount, row.key, index, row.label)
                                    .adjustedPrice !== null
                                    ? fCurrency(
                                        `${applyDiscounts(
                                            item[row.key],
                                            row.discount,
                                            row.key,
                                            index,
                                            row.label
                                        ).adjustedPrice}`
                                    )
                                    : '/'
                                : fCurrency(`${item[row.key]}`)}
                            {row.discount && (
                                <IconButton
                                    disabled={!data.editable}
                                    onClick={() => {
                                        const { key } = row;
                                        const { stationId, id } = pricePreviewData[index];
                                        const priceOneWay = applyDiscounts(
                                            item['priceOneWay'],
                                            row.discount,
                                            'priceOneWay',
                                            index
                                        ).adjustedPrice;
                                        const priceReturnTicket = applyDiscounts(
                                            item['returnTicketPrice'],
                                            row.discount,
                                            'returnTicketPrice',
                                            index
                                        ).adjustedPrice;
                                        const priceMonthly = applyDiscounts(
                                            item['priceMonthly'],
                                            row.discount,
                                            'priceMonthly',
                                            index
                                        ).adjustedPrice;
                                        const OBJ = {
                                            id: id,
                                            rateCardId: selectedRateCard,
                                            station1Id: 1645,
                                            station2Id: stationId,
                                            ticketTypeId: row?.discount?.id || null,
                                            priceOneWay,
                                            priceReturnTicket,
                                            priceMonthly,
                                            rateCardName: item.rateCardName,
                                            stationName: item.stationName,
                                            label: row.label,
                                        };
                                        setOpenDialog({
                                            open: true,
                                            data: { ...OBJ, key: key },
                                        });
                                    }}
                                    size="small"
                                    sx={{ marginLeft: 1 }}
                                >
                                    <Iconify width={15} icon="mdi:pencil" />
                                </IconButton>
                            )}
                        </Stack>
                    </TableCell>

                ))}
            </TableRow>
        ));
    };

    useLayoutEffect(() => {
        handlePrices()
    }, [refresh]);

    const handlePrices = (id) => {
        const selectedRateCardID = id || selectedRateCard

        if (selectedRateCardID) {
            setLoading(true);
            const pricePreviewPromise = getPricePreviewByLineIdRateCardId({...data, rateCardId: selectedRateCardID});
            const ticketTypesPromise = getAllTicketsTypeByOperatorIdAPI(data?.tripId);

            Promise.all([pricePreviewPromise, ticketTypesPromise])
                .then(([pricePreviewResponse, ticketTypesResponse]) => {
                    if (data?.arrStationId) {
                        setPricePreviewData(pricePreviewResponse?.data?.filter(f => f.stationId === data?.arrStationId) || []);
                    } else {
                        setPricePreviewData(pricePreviewResponse?.data || []);
                    }
                    setDiscountData(ticketTypesResponse?.data?.filter(f => f.operator !== null && f.active) || []);
                })
                .catch((error) => {
                    console.error(error, "Error");
                    enqueueSnackbar(translate("errorOccurred"), {variant: "error"});
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }
    const onRateCardChange = (e) => {
        setLoading(true);
        setSelectedRateCard(e.target.value)
        if (e.target.value) {
            handlePrices(e.target.value)
        }
    }

    return (
        <Dialog fullWidth maxWidth="xl" open={data.open} onClose={onClose}>
            <DialogContent>
                {loading ? "Učitavanje podataka..." : (
                    <div>
                        <Stack
                            direction="row"
                            spacing={1.2}
                            justifyContent="center"
                            alignItems="center"
                            width={1}
                            mb={2}
                            sx={{position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white'}}
                        >
                            <Typography variant="subtitle1" fontSize="0.875rem">Linija: {data?.lineName}</Typography>
                            <Typography variant="subtitle1"
                                        fontSize="0.875rem">Prevoznik: {data?.operatorName}</Typography>
                        </Stack>
                        {data?.showRateCardSelect &&
                            <Stack alignItems="center">
                                <FormControl
                                    size="small"
                                    sx={{width: {xs: 1, md: 440}, mb: 2}}>
                                    <InputLabel sx={{'&.Mui-focused': {color: 'text.primary'}}}>Izaberite cenovnik</InputLabel>
                                    <Select
                                        value={selectedRateCard}
                                        name="rateCardId"
                                        onChange={onRateCardChange}
                                        input={<OutlinedInput label="Izaberite cenovnik"/>}>
                                        {rateCards.map((option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                                sx={{
                                                    p: 1,
                                                    mx: 1,
                                                    my: 0.5,
                                                    borderRadius: 0.75,
                                                    typography: 'body2',
                                                    '&:first-of-type': {mt: 0},
                                                    '&:last-of-type': {mb: 0},
                                                }}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>
                        }

                        <TableContainer component={Paper} sx={{maxHeight: '80vh', overflow: 'auto'}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{
                                            border: 1,
                                            borderColor: 'divider',
                                            fontSize: '0.875rem',
                                            padding: '4px',
                                            position: 'sticky',
                                            top: 0,
                                            backgroundColor: 'white',
                                            zIndex: 2
                                        }}></TableCell>
                                        {pricePreviewData.map((item, index) => (
                                            <TableCell
                                                key={index}
                                                align="center"
                                                sx={{
                                                    border: 1,
                                                    borderColor: 'divider',
                                                    fontSize: '0.875rem',
                                                    padding: '4px',
                                                    position: 'sticky',
                                                    top: 0,
                                                    zIndex: 2
                                                }}
                                            >
                                                <Typography variant="subtitle2"
                                                            fontSize="0.875rem">{item.stationName}</Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>

                                </TableHead>
                                <TableBody>
                                    {renderTableRows()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </DialogContent>
            <PriceChangeDialog
                setLoading={setLoading}
                loading={loading}
                open={openDialog.open}
                data={openDialog.data}
                handleClose={(changedField) => {
                    setOpenDialog({ open: false, data: null });
                    if (changedField) {
                        setLastChangedField(changedField);
                    }
                    triggerRefresh();
                }}
            />
        </Dialog>
    );

}
