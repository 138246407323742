import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    getAllAllotmentsAPI,
    insertAllotmentAPI,
    insertInstantAllotmentAPI,
    updateAllotmentAPI
} from "../../api/alltoments";

export const fetchAllAllotments = createAsyncThunk('fetchAllAllotments', async (data) => {
    return await getAllAllotmentsAPI(data)
})

export const insertAllotment = createAsyncThunk('insertAllotment', async (data, thunkAPI) => {
    try {
        return await insertAllotmentAPI(data);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.data ? error.data : error);
    }
});

export const insertInstantAllotment = createAsyncThunk('insertInstantAllotment', async (data, thunkAPI) => {
    try {
        return await insertInstantAllotmentAPI(data);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.data ? error.data : error);
    }
});

export const updateAllotment = createAsyncThunk('updateAllotment', async (data, thunkAPI) => {
    try {
        return await updateAllotmentAPI(data);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.data ? error.data : error);
    }
})


const initialState = {
    allotmentsList: [],
    foundAllotments: [],
    loadingAllotments: false,
}

const allotmentsSlice = createSlice({
    name: 'allotments',
    initialState,
    reducers: {
        searchAllotmentSlice: (state, {payload}) => {
            if (payload?.content) {
                state.foundAllotments = payload;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllAllotments.pending, (state) => {
                state.loadingAllotments = true;
            })
            .addCase(fetchAllAllotments.fulfilled, (state, {payload}) => {
                if (payload?.data) {
                    state.foundAllotments = payload.data;
                    state.allotmentsList = payload.data;
                }
                state.loadingAllotments = false;
            })
            .addCase(fetchAllAllotments.rejected, (state) => {
                state.loadingAllotments = false;
            })
            .addCase(insertAllotment.pending, (state) => {
                state.loadingAllotments = true;
            })
            .addCase(insertAllotment.fulfilled, (state) => {
                state.loadingAllotments = false;
            })
            .addCase(insertAllotment.rejected, (state) => {
                state.loadingAllotments = false;
            })
            .addCase(insertInstantAllotment.pending, (state) => {
                state.loadingAllotments = true;
            })
            .addCase(insertInstantAllotment.fulfilled, (state) => {
                state.loadingAllotments = false;
            })
            .addCase(insertInstantAllotment.rejected, (state) => {
                state.loadingAllotments = false;
            })
    },
});

export const {searchAllotmentSlice} = allotmentsSlice.actions;
export default allotmentsSlice.reducer;
