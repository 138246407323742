import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    activateLineApi,
    checkIfStationExistOnLineAPI,
    copyLineApi,
    deactivateLineApi,
    deleteAllByLineIdAPI,
    getAllLinesAPI,
    getLineByIdAPI,
    insertLineByIdAPI,
    searchLineAPI,
    updateBySubOperatorByIdAPI,
    updateLineByIdAPI
} from "../../api/lines";
import {changeStatusAPI} from "../../api/vehicle";

export const fetchAllLines = createAsyncThunk('fetchAllLines', async (payload) => {
    return await getAllLinesAPI(payload);
});

export const getLineByFilter = createAsyncThunk('getLineByFilter', async (payload) => {
    return await searchLineAPI(payload);
});

export const getLineById = createAsyncThunk('getLineById', async (payload) => {
    return await getLineByIdAPI(payload);
});

export const getStationIdByLineId = createAsyncThunk('getStationIdByLineId', async (payload) => {
    return await checkIfStationExistOnLineAPI(payload);
});

export const activateLineById = createAsyncThunk('activateLineById', async (id, {rejectWithValue}) => {
    try {
        const response = await activateLineApi(id);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
});

export const deactivateLine = createAsyncThunk('deactivateLine', async (id, {rejectWithValue}) => {
    try {
        const response = await deactivateLineApi(id);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})

export const deleteAllByLineId = createAsyncThunk('deleteAllByLineId', async (id, {rejectWithValue}) => {
    try {
        const response = await deleteAllByLineIdAPI(id);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
});

export const updateLineById = createAsyncThunk('updateLineById', async (payload) => {
    return await updateLineByIdAPI(payload);
});

export const updateBySubOperatorById = createAsyncThunk('updateBySubOperatorById', async (payload, thunkAPI) => {
    try {
        return await updateBySubOperatorByIdAPI(payload);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.data ? error.data : error);
    }
});

export const insertLine = createAsyncThunk('insertLine', async (payload) => {
    return await insertLineByIdAPI(payload);
});

export const copyLine = createAsyncThunk('copyLine', async (payload,{rejectWithValue}) => {
    try {
        const response = await copyLineApi(payload);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})

const initialState = {
    foundLines: [],
    linesData: {},
    allLinesBackend: [],
    insertedLine: null,
    loadingAllLines: false,
    lineError: null,
    searchParam: ""
};

export const linesSlice = createSlice({
    name: 'lineAutocomplete',
    initialState,
    reducers: {
        specialLineLoading: (state, {payload}) => {
            if (payload) {
                state.loadingAllLines = payload
            }
        },
        searchLineSlice: (state, {payload}) => {
            if (payload?.content) {
                state.foundLines = payload;
                state.loadingAllLines = false
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllLines.pending, (state) => {
                state.loadingAllLines = true;
            })
            .addCase(fetchAllLines.fulfilled, (state, {payload}) => {
                if (payload.data) {
                    state.foundLines = payload.data;
                }
                state.loadingAllLines = false;
            })
            .addCase(getLineById.pending, (state) => {
                state.loadingAllLines = true;
            })
            .addCase(getLineById.fulfilled, (state, action) => {
                state.loadingAllLines = false;
                state.linesData[action.meta.arg] = action.payload;
            })
            .addCase(getStationIdByLineId.pending, (state) => {
                state.loadingAllLines = true;
            })
            .addCase(getStationIdByLineId.fulfilled, (state) => {
                state.loadingAllLines = false;
            })
            .addCase(getStationIdByLineId.rejected, (state) => {
                state.loadingAllLines = false;
            })
            .addCase(updateLineById.pending, (state) => {
                state.loadingAllLines = true;
            })
            .addCase(updateLineById.fulfilled, (state) => {
                state.loadingAllLines = false;
            })
            .addCase(updateLineById.rejected, (state) => {
                state.loadingAllLines = false;
            })
            .addCase(updateBySubOperatorById.pending, (state) => {
                state.loadingAllLines = true;
            })
            .addCase(updateBySubOperatorById.fulfilled, (state) => {
                state.loadingAllLines = false;
            })
            .addCase(updateBySubOperatorById.rejected, (state) => {
                state.loadingAllLines = false;
            })
            .addCase(insertLine.pending, (state) => {
                state.loadingAllLines = true;
            })
            .addCase(insertLine.fulfilled, (state) => {
                state.loadingAllLines = false;
            })
            .addCase(insertLine.rejected, (state, action) => {
                state.loadingAllLines = false;
                state.lineError = action.payload?.msg || "Unknown error";
            })
            .addCase(copyLine.fulfilled, (state, {payload}) => {
                const content = state.foundLines.content;
                if (Array.isArray(content)) {
                   content.push(payload)
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(deactivateLine.fulfilled, (state, {meta}) => {
                const content = state.foundLines.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex(line => line.id === meta.arg);
                    if (index !== -1) {
                        content[index].status = !content[index].status;
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(activateLineById.fulfilled, (state, {meta}) => {
                const content = state.foundLines.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex(line => line.id === meta.arg);
                    if (index !== -1) {
                        content[index].status = !content[index].status;
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(deleteAllByLineId.fulfilled, (state, {meta}) => {
                let content = state.foundLines.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex(line => line.id === meta.arg);
                    if (index !== -1) {
                        content.splice(index, 1);
                        state.foundLines.totalElements -= 1;
                        state.foundLines.numberOfElements = content.length;
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            });
    }
});

export const {specialLineLoading, searchLineSlice} = linesSlice.actions;
export const linesReducer = linesSlice.reducer;
