import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit';
import {AUTH, usersImageStorage} from "../../auth/FirebaseContext";
import {ref, uploadBytes} from "firebase/storage";

// export const fetchUserLogoUrl = createAsyncThunk('fetchUserLogoUrl', async () => {
//     const imageRef = ref(usersImageStorage, `${AUTH.currentUser.uid}.png`);
//     return await getDownloadURL(imageRef);
// })


export const insertUserImage = createAsyncThunk('insertUserImage', async (image, {
    rejectWithValue,
}) => {
    try {
        let imageRef = ref(usersImageStorage, `${AUTH.currentUser.uid}.png`);
        return await uploadBytes(imageRef, image);
    } catch (e) {
        return rejectWithValue(e);
    }
})

const initialState = {
    logo: undefined,
    loading: false,
    error: undefined
}

export const logoSlice = createSlice({
    name: 'logo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // .addCase(fetchUserLogoUrl.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(fetchUserLogoUrl.fulfilled, (state, {payload}) => {
            //     state.logo = payload;
            //     state.loading = false;
            // })
            // .addCase(fetchUserLogoUrl.rejected, (state) => {
            //     state.loading = false;
            // })
            .addMatcher(
                isAnyOf(insertUserImage.pending, insertUserImage.fulfilled, insertUserImage.rejected),
                (state, {meta}) => {
                    state.loading = meta.requestStatus === 'pending';
                }
            );
    },
});

export const logoReducer = logoSlice.reducer;
