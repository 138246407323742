import {customAxiosBE} from "../config";
import moment from "moment/moment";
import {parsePaginationParams} from "../../utils/other";

export async function putTripAPI(data) {
    return customAxiosBE.put(`/trip`, data)
}

export async function updateTripByLine(data) {
    return customAxiosBE.put(`/trip/updateTripByLine`, data)
}

export async function insertTripAPI(data) {
    return customAxiosBE.post(`/trip`, data)
}

export async function getTripByIdApi(id) {
    return customAxiosBE.get(`/trip/${id}`)
}

export async function getTripHasVehiclesByIdApi(id) {
    return customAxiosBE.get(`trip/tripHasVehicles/${id}`)
}

export async function getLineByTripId(id) {
    return customAxiosBE.get(`/trip/${id}/line`)
}

export async function getPricePreviewByLineIdRateCardId(data) {
    const {lineId, rateCardId, operatorId} = data
    if (operatorId) {
        return customAxiosBE.get(`/trip/price-preview?lineId=${lineId}&rateCardId=${rateCardId}&operatorId=${operatorId}`)
    }
    return customAxiosBE.get(`/trip/price-preview?lineId=${lineId}&rateCardId=${rateCardId}`)
}

export async function getLinkedTripPricePreviewAPI(data) {
    const {linkID, rateCardId, stationId} = data
    return customAxiosBE.get(`/trip/price-preview-linked?linkID=${linkID}&rateCardId=${rateCardId}&stationId=${stationId}`)
}

export async function getByLineId(id) {
    return customAxiosBE.get(`/trip/byLine/${id}`)
}

export async function getByLineName(lineName) {
    try {
        return await customAxiosBE.post(`/trip/byLineName`, lineName, {
            headers: {
                'Content-Type': 'text/plain'
            }
        });
    } catch (error) {
        console.error("Error fetching trips by line name:", error);
    }
}

export async function searchTripApi(data) {
    return customAxiosBE.post(`/trip/search`, data)
}

// POVRATAK IZ MESTA
export async function searchTripReturnApi(data) {
    return customAxiosBE.post(`/trip/searchTripReturn`, data)
}

export async function getAllTripTimesFromNSByOperatorApi(data) {
    return await customAxiosBE.post(`/trip/times`, data)
}

export async function postInstantTripAPI(data) {
    return await customAxiosBE.post(`/trip/instantTrip`, data)
}

export async function getAllTripByOperator(data) {
    const parsedParams = parsePaginationParams(data)
    return customAxiosBE.get(`/trip/byOperator?${parsedParams}`)
}

export async function getAllByOperatorWithStatusOfDiscount(ticketTypeId) {
    return customAxiosBE.get(ticketTypeId ? `/trip/getAllByOperatorWithStatusOfDiscount?ticketTypeId=${ticketTypeId}`:`/trip/getAllByOperatorWithStatusOfDiscount`)
}

export function deactivateTripApi(id) {
    return customAxiosBE.put(`/trip/deactivate/${id}`)
}

export function activateTripApi(obj) {
    return customAxiosBE.put(`/trip/activate`, obj)
}

export function cancelTripApi(id, payload) {
    return customAxiosBE.post(`/trip/${id}/cancelToPeriod`, payload);
}

export function getTripsByDepStationAndArrStation(depId, arrId, tripId) {
    return customAxiosBE.get(`/trip/depStation/${depId}/arrStation/${arrId}?tripId=${tripId}`)
}

export function findTripForReservation(data) {
    return customAxiosBE.post(`/trip/findTripForReservation`, data)
}

export function getTripsByDepStationAndArrStationByOperatorId(depId, arrId, operatorId) {
    return customAxiosBE.get(`/trip/depStation/${depId}/arrStation/${arrId}/operatorId/${operatorId}`)
}

export function getAllInverseReturnTrips(data) {
    return customAxiosBE.post(`/trip/allInverseReturnTrips`, data)
}

export async function insertCommentAPI(data) {
    return customAxiosBE.post(`/trip/addComment`, data)
}

export async function getCommentsAPI(id, date) {
    return customAxiosBE.get(`/trip/${id}/info?date=${date}`)
}

export async function getAllLinkedTrioOptions(stationId) {
    return customAxiosBE.get(`/trip/linkedTripOptions/${stationId}`)
}

export async function getitinerariesAPI(id, date) {
    return customAxiosBE.post(`/trip/${id}/itineraries`, {
        tripTime: moment(date).format("YYYY-MM-DD")
    })
}

export async function occupiedSeatsAPI({
                                           tripId,
                                           journeyDate,
                                           fromStationId,
                                           toStationId
                                       }) {
    return customAxiosBE.get(`/trip/${tripId}/seats/occupied?journeyDate=${journeyDate}&depId=${fromStationId}&arrId=${toStationId}`);
}


export async function allSeats(data) {
    const {id, date, depId, arrId} = data
    let url = `/trip/${id}/seats?date=${date}`;
    if (depId) {
        url += `&depId=${depId}`;
    }
    if (arrId) {
        url += `&arrId=${arrId}`;
    }
    return customAxiosBE.get(url);
}
