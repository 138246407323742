import {useLayoutEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
// @mui
import {alpha} from "@mui/material/styles";
import {Box, CircularProgress, Divider, MenuItem, Stack, Typography} from "@mui/material";
// routes
import {PATH_AUTH, PATH_DASHBOARD} from "../../../routes/path";
// auth
// components
import {CustomAvatar} from "../../../components/custom-avatar";
import {useSnackbar} from "notistack";
import MenuPopover from "../../../components/menu-popover";
import {IconButtonAnimate} from "../../../components/animate";
import {getIniLoggedUser, getSessionOperator, removeSessionOperator} from "../../../helper/session";
import {findRoleName} from "../../../helper/other";
import {useDispatch} from "../../../redux/store";
import {useSelector} from "react-redux";
import {fetchAllUserLicense} from "../../../redux/slices/license";
import {fetchEsirBalance} from "../../../redux/slices/esirInvoices";
import moment from "moment/moment";
import {AUTH} from "../../../auth/FirebaseContext";
import ChangeBusOperatorDialog from "../ChangeBusOperatorDialog";
import {useAuthContext} from "../../../auth/useAuthContext";
import {useLocales} from "../../../locales";
import TestDialog from "./TestDialog";
import ReportProblemDialog from "../../../components/ReportProblemDialog";

// ----------------------------------------------------------------------

const OPTIONS = [{
    label: "POČETNA", linkTo: "/"
}, {
    label: "PODEŠAVANJA", linkTo: PATH_DASHBOARD.general.settings
}];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const navigate = useNavigate();
    const {translate} = useLocales();
    const {changeOperator, user} = useAuthContext();
    const dispatch = useDispatch();
    const operator = getSessionOperator();
    const primaryUser = getIniLoggedUser();
    const isPrimary = primaryUser?.uid === AUTH.currentUser.uid

    const {balance} = useSelector((state => state.esirInvoices))
    const {licenses} = useSelector((state => state.license))

    const {enqueueSnackbar} = useSnackbar();

    const [openPopover, setOpenPopover] = useState(null);
    const [openReportModal, setOpenReportModal] = useState(false);
    const [openBusLoginDialog, setOpenLoginDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openTestDialog, setOpenTestDialog] = useState(false);

    useLayoutEffect(() => {
        dispatch(fetchAllUserLicense())
        dispatch(fetchEsirBalance())
    }, [])

    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const handleLogout = async () => {
        try {
            setLoading(true);
            await changeOperator(primaryUser.uid)
            removeSessionOperator()
            navigate(PATH_AUTH.login, {replace: true});
            handleClosePopover();
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Došlo je do greške!', {variant: 'error'});
        } finally {
            setLoading(false)
        }
    };

    const handleClickItem = (path) => {
        handleClosePopover();
        navigate(path);
    };

    const formatDateLicence = (prop) => {
        if (prop !== undefined && prop !== "") {
            return moment(prop).format('DD.MM.YYYY');
        } else {
            return "";
        }
    }

    const handleOpenBusModal = () => {
        setOpenLoginDialog(true)
    }

    const handleReturnToMain = async () => {
        try {
            setLoading(true)
            await changeOperator(primaryUser.uid)
            navigate(PATH_DASHBOARD.general.app)
        } catch (e) {
            enqueueSnackbar(translate("error"), {variant: "error"})
        } finally {
            setLoading(false)
        }
    }

    const handleReportProblem = () => {
        setOpenReportModal(true)
    }

    const handleCloseReportProblem = () => {
        setOpenReportModal(false)
    }

    const handlePrintTest = async () => {
        setOpenTestDialog(true)
    }

    const handleCloseTestDialog = () => {
        setOpenTestDialog(false)
    }

    return (<>
        <IconButtonAnimate
            onClick={handleOpenPopover}
            sx={{
                p: 0, ...(openPopover && {
                    "&:before": {
                        zIndex: 1,
                        content: "''",
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                        position: "absolute",
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8)
                    }
                })
            }}>
            <CustomAvatar src={operator?.photoURL} alt={operator?.username} name={operator?.username}/>
        </IconButtonAnimate>

        <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{width: 300, p: 0}}>
            <Box sx={{my: 1.5, px: 2.5}}>
                <Typography variant="subtitle2" noWrap>
                    {operator?.username}
                </Typography>

                <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                    {findRoleName(operator?.group)}
                </Typography>
            </Box>

            <Divider sx={{borderStyle: 'dashed'}}/>
            <MenuItem disabled={loading} onClick={handleOpenBusModal} sx={{m: 1, textWrap: "wrap"}}>
                {loading ? <CircularProgress size={20}/> : `PRIJAVLJENI NA: ${user?.name}`}
            </MenuItem>
            <Divider sx={{borderStyle: 'dashed'}}/>
            <Stack sx={{p: 1}}>
                {OPTIONS.map((option) => (
                    <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
                        {option.label}
                    </MenuItem>))}
                <MenuItem key={`test-karta`} onClick={handlePrintTest}>
                    TEST KARTA
                </MenuItem>
            </Stack>

            <Divider sx={{borderStyle: 'dashed'}}/>

            <Box sx={{my: 1.5, px: 2.5}}>
                <Stack direction="row" spacing={3}>
                    <img alt="bank" style={{width: 25}} src={`/assets/banking-coin.svg`}/>
                    <Stack direction="column">
                        <Typography variant="caption" noWrap>BALANS: {balance ?? 0}</Typography>
                        <Typography variant="caption">VAŽENJE LICENCE: </Typography>
                        <Typography
                            variant="caption">{formatDateLicence(licenses.find(license => license.productID === 1)?.expiredDate.substring(0, 10))}</Typography>
                    </Stack>
                </Stack>
            </Box>

            <Divider sx={{borderStyle: "dashed"}}/>

            {!isPrimary &&
                <MenuItem disabled={loading} onClick={handleReturnToMain} sx={{m: 1, whiteSpace: 'normal'}}>
                    {loading ? <CircularProgress size={20}/> : `POVRATAK NA: ${primaryUser?.name}`}
                </MenuItem>
            }
            <Divider sx={{borderStyle: 'dashed'}}/>
            <MenuItem disabled={loading} onClick={handleReportProblem} sx={{m: 1, whiteSpace: 'normal'}}>
                {loading ? <CircularProgress size={20}/> : `UNESI PROBLEM ILI ZAHTEV`}
            </MenuItem>
            <Divider sx={{borderStyle: 'dashed'}}/>
            <MenuItem disabled={loading} onClick={handleLogout} sx={{m: 1, color: "red", fontWeight: 600}}>
                {loading ? <CircularProgress size={20}/> : "ODJAVI SE"}
            </MenuItem>
        </MenuPopover>

        {openBusLoginDialog &&
            <ChangeBusOperatorDialog
                open={openBusLoginDialog}
                onClose={() => setOpenLoginDialog(false)}
            />}
        {openTestDialog &&
            <TestDialog
                open={openTestDialog}
                onClose={handleCloseTestDialog}
            />
        }
        {openReportModal && <ReportProblemDialog open={openReportModal} onClose={handleCloseReportProblem}/>}
    </>);
}
