import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography } from "@mui/material";
import { getSessionAppVersion, getSessionOperator } from "../../helper/session";
import { AUTH } from "../../auth/FirebaseContext";
import axios from "axios";
import moment from "moment";
import { ASANA_ACCESS_TOKEN, ASANA_PROJECT_ID, ASANA_USER_ID, ASANA_WORKSPACE_ID } from "../../config";
import { ASANA_TASK_TAGS } from "../../constants/other";
import {useSnackbar} from "notistack";

export default function ReportProblemDialog({ open, onClose }) {
    const {enqueueSnackbar} = useSnackbar();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [requestType, setRequestType] = useState("");
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const formattedDateTime = moment().format("DD.MM.YYYY HH:mm:ss");

    useEffect(() => {
        if (open) {
            setTitle("");
            setDescription("");
            setRequestType("");
            setIsSubmitted(false);
        }
    }, [open]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);

        if (!title || !description || !requestType) return;

        try {
            setLoading(true);
            const cashier = getSessionOperator();
            const version = getSessionAppVersion();
            const userUid = AUTH.currentUser.uid;

            await axios.post("https://app.asana.com/api/1.0/tasks", {
                data: {
                    completed: false,
                    approval_status: "pending",
                    name: title,
                    assignee: `${ASANA_USER_ID}`,
                    notes: JSON.stringify({
                        description: description,
                        createdAt: formattedDateTime,
                        cashier: cashier,
                        version: version,
                        FirebaseUID: userUid,
                        tag: requestType === "problem" ? ASANA_TASK_TAGS.problem : ASANA_TASK_TAGS.request
                    }),
                    projects: [`${ASANA_PROJECT_ID}`],
                    workspace: `${ASANA_WORKSPACE_ID}`
                }
            }, {
                headers: {
                    accept: "application/json",
                    "content-type": "application/json",
                    authorization: `Bearer ${ASANA_ACCESS_TOKEN}`
                }
            });
            enqueueSnackbar("Uspešnan unos")
            onClose();
        } catch (e) {
            console.error("Greška prilikom slanja zahteva:", e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>Unesi problem ili zahtev</DialogTitle>
                <DialogContent>
                    <TextField
                        sx={{ mt: 2 }}
                        fullWidth
                        autoFocus
                        label="Naslov"
                        size="small"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        error={isSubmitted && !title}
                        helperText={isSubmitted && !title ? "Naslov je obavezan" : ""}
                    />

                    <TextField
                        sx={{ mt: 2 }}
                        fullWidth
                        multiline
                        rows={5}
                        label="Opis problema"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        error={isSubmitted && !description}
                        helperText={isSubmitted && !description ? "Opis je obavezan" : ""}
                    />

                    <FormControl sx={{ mt: 2 }}  error={isSubmitted && !requestType}>
                        <FormLabel>Tip prijave</FormLabel>
                        <RadioGroup
                            row
                            value={requestType}
                            onChange={(e) => setRequestType(e.target.value)}
                        >
                            <FormControlLabel value="problem" control={<Radio />} label="Problem" />
                            <FormControlLabel value="request" control={<Radio />} label="Zahtev" />
                        </RadioGroup>
                        {isSubmitted && !requestType && <Typography sx={{ color: "red", fontSize: 12 }}>Morate izabrati tip prijave</Typography>}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} disabled={loading}>
                        Zatvori
                    </Button>
                    <Button type="submit" variant="contained" disabled={loading}>
                        {loading ? "Slanje..." : "Potvrdi"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
